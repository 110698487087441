// @flow
import { all } from 'redux-saga/effects';
import authSaga from './auth/saga';
import layoutSaga from './layout/saga';
import appMenuSaga from './appMenu/saga';
import folderSaga from './folder/saga'
import toolbarSaga from './toolbar/saga'
import shortcutSetupModalSaga from './shortcutmodal/saga'
import compactViewSaga from './compactView/saga'

export default function* rootSaga(getState: any): any {
    yield all([authSaga(), layoutSaga(), appMenuSaga(), folderSaga(), toolbarSaga(), shortcutSetupModalSaga(), compactViewSaga()]);
}
