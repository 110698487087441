import {
    SET_VIEW,
    GET_VIEW,
    SAVE_COMPLETE,
    SAVE_FAILED
} from './constants'
import cloneDeep from 'lodash/cloneDeep';
import Fuse from 'fuse.js';

const INIT_STATE = {
    viewType: "S",
    success: false

};

type CompactViewAction = { type: string, payload: {} | string };
let target = null;
const CompactView = (state: State = INIT_STATE, action: CompactViewAction) => {

    switch (action.type) {
        case SET_VIEW:

            return {
                ...state,
                viewType: action.payload
            }
        case SAVE_COMPLETE:

            return {
                ...state,
                viewType: action.payload,
                success: true
            }
        case SAVE_FAILED:

            return {
                ...state,
                viewType: action.payload,
                success: false
            }

        case GET_VIEW:

            return {
                ...state,
            }

        default:
            return state
    }
}


export default CompactView