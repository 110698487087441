import { CHANGE_TRAFFIC_LIGHT, CHANGE_IFRAM_URL, GET_ADVANCE_SEARCH_PARAMS, CHANGE_ADD_NEW_URL, CHANGE_MY_FOLDER_ID } from './constants';

type ComponentAction = { type: string, payload?: string | null };
export const changeTrafficLight = (lightType: string): ComponentAction => ({
    type: CHANGE_TRAFFIC_LIGHT,
    payload: lightType,
});

export const changeIframeUrl = (url: string, selectedButon: string): ComponentAction => ({
    type: CHANGE_IFRAM_URL,
    payload: { url: url, selectedButon: selectedButon },
});

export const advanceSearchParams = (params: []): ComponentAction => ({
    type: GET_ADVANCE_SEARCH_PARAMS,
    payload: { params: params },
});

export const changeAddNewUrl = (
    url: string,
    objectType: string,
    favourite: Boolean,
    ishide: Boolean,
    folderId: int,
    folderType: string
): ComponentAction => ({
    type: CHANGE_ADD_NEW_URL,
    payload: {
        url: url,
        objectType: objectType,
        favourite: favourite,
        ishide: ishide,
        folderId: folderId,
        folderType: folderType,
    },
});

export const changeMyFolderId = folderId => ({
    type: CHANGE_MY_FOLDER_ID,
    payload: folderId
});