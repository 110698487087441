export const FOLDER_MAINTENANCE_OPENED = 'FOLDER_MAINTENANCE_OPENED'
export const API_PREFIX = '/netcourier-data/workspace/data/'

// Folder
// export const VALIDATE_FOLDER = 'VALIDATE_FOLDER'
export const SAVE_FOLDER = 'SAVE_FOLDER'
export const SAVING_FOLDER = 'SAVING_FOLDER'
export const SAVE_FOLDER_COMPLETE = 'SAVE_FOLDER_COMPLETE'
export const CLEAN_UP_DATA = 'CLEAN_UP_DATA'
export const SAVE_FOLDER_FAILED = 'SAVE_FOLDER_FAILED'
export const TRIGGER_FORM_SUBMITTED = 'TRIGGER_FORM_SUBMITTED'

// Folder rules
export const LOAD_FOLDER_RULES_DATA = 'LOAD_FOLDER_RULES_DATA'
export const LOADING_FOLDER_RULES_DATA = 'LOADING_FOLDER_RULES_DATA'
export const LOAD_FOLDER_RULES_DATA_SUCCESS = 'LOAD_FOLDER_RULES_DATA_SUCCESS'
export const FOLDER_NAME_CHANGED = 'FOLDER_NAME_CHANGED'
export const PARENT_FOLDER_CHANGED = 'PARENT_FOLDER_CHANGED'
export const ACCESS_GRANTED_TO_CHANGED = 'ACCESS_GRANTED_TO_CHANGED'
export const REFRESH_TABLE_AT_INTERVAL_CHANGED = 'REFRESH_TABLE_AT_INTERVAL_CHANGED'
export const SEQUENCE_NUMBER_CHANGED = 'SEQUENCE_NUMBER_CHANGED'
export const OBJECT_TYPE_CHANGED = 'OBJECT_TYPE_CHANGED'
export const DETAILS_URI_CHANGED = 'DETAILS_URI_CHANGED'
export const LIST_URI_CHANGED = 'LIST_URI_CHANGED'
export const TABLE_ACCESSED_CHANGED = 'TABLE_ACCESSED_CHANGED'
export const SET_FIRST_FILTER_BY_DEFAULT_CHANGED = 'SET_FIRST_FILTER_BY_DEFAULT_CHANGED'
export const SET_FIRST_ROW_BY_DEFAULT_CHANGED = 'SET_FIRST_ROW_BY_DEFAULT_CHANGED'
export const PREVIEW_WINDOW_CHANGED = 'PREVIEW_WINDOW_CHANGED'
export const RESULT_PER_PAGE_CHANGED = 'RESULT_PER_PAGE_CHANGED'
export const FOLDER_SELECT_FROM_CHANGED = 'FOLDER_SELECT_FROM_CHANGED'
export const FOLDER_SELECT_TO_NAME_CHANGED = 'FOLDER_SELECT_TO_NAME_CHANGED'

// Column rules
export const LOAD_COLUMN_RULES_DATA = 'LOAD_COLUMN_RULES_DATA'
export const LOADING_COLUMN_RULES_DATA = 'LOADING_COLUMN_RULES_DATA'
export const LOAD_COLUMN_RULES_DATA_SUCCESS = 'LOAD_COLUMN_RULES_DATA_SUCCESS'
export const COLUMNS_TO_SHOW_CHANGED = 'COLUMNS_TO_SHOW_CHANGED'
export const DEFAULT_SORT_CHANGED = 'DEFAULT_SORT_CHANGED'
export const SORT_DIRECTION_CHANGED = 'SORT_DIRECTION_CHANGED'
export const HANDLE_SORT_ORDER_CHANGED = 'HANDLE_SORT_ORDER_CHANGED'
export const HANDLE_COLUMN_ALIAS_CHANGED = 'HANDLE_COLUMN_ALIAS_CHANGED'

// Selection rules
export const LOAD_SELECTION_RULES_DATA = 'LOAD_SELECTION_RULES_DATA'
export const LOADING_SELECTION_RULES_DATA = 'LOADING_SELECTION_RULES_DATA'
export const LOAD_SELECTION_RULES_DATA_SUCCESS = 'LOAD_SELECTION_RULES_DATA_SUCCESS'
export const FIELD_CHANGED = 'FIELD_CHANGED'
export const ADD_SELECTION_RULE = 'ADD_SELECTION_RULE'
export const DELETE_SELECTION_RULE = 'DELETE_SELECTION_RULE'
export const MOVE_UP_SELECTION_RULE = 'MOVE_UP_SELECTION_RULE'
export const MOVE_DOWN_SELECTION_RULE = 'MOVE_DOWN_SELECTION_RULE'
export const SELECTION_TYPE_CHANGED = 'SELECTION_TYPE_CHANGED'
export const SELECTION_FIELD_CHANGED = 'SELECTION_FIELD_CHANGED'
export const SELECTION_TEST_CHANGED = 'SELECTION_TEST_CHANGED'
export const SELECTION_VALUE_CHANGED = 'SELECTION_VALUE_CHANGED'
export const SELECTION_FOREGROUND_CHANGED = 'SELECTION_FOREGROUND_CHANGED'
export const SELECTION_BACKGROUND_CHANGED = 'SELECTION_BACKGROUND_CHANGED'

// Action rules
export const MOVE_UP_ACTION_RULE = 'MOVE_UP_ACTION_RULE'
export const MOVE_DOWN_ACTION_RULE = 'MOVE_DOWN_ACTION_RULE'
export const LOAD_ACTION_RULES_DATA = 'LOAD_ACTION_RULES_DATA'
export const LOADING_ACTION_RULES_DATA = 'LOADING_ACTION_RULES_DATA'
export const LOAD_ACTION_RULES_DATA_SUCCESS = 'LOAD_ACTION_RULES_DATA_SUCCESS'
export const ADD_ACTION_RULE = 'ADD_ACTION_RULE'
export const DELETE_ACTION_RULE = 'DELETE_ACTION_RULE'

export const GRID_SELECT_VALUE_CHANGED = 'GRID_SELECT_VALUE_CHANGED'
export const GRID_INPUT_VALUE_CHANGED = 'GRID_INPUT_VALUE_CHANGED'

// Input types
export const TEXT_INPUT = 'FREETXT'
export const SINGLE_SELECT = 'SINGLE_SELECT'
export const MULTI_SELECT = 'MULTI_SELECT'
export const SINGLE_SEARCH = 'SINGLE_SEARCH'
export const MULTI_SEARCH = 'MULTI_SEARCH'

// Widget rules
export const LOAD_WIDGET_RULES_DATA = 'LOAD_WIDGET_RULES_DATA'
export const LOADING_WIDGET_RULES_DATA = 'LOADING_WIDGET_RULES_DATA'
export const LOAD_WIDGET_RULES_DATA_SUCCESS = 'LOAD_WIDGET_RULES_DATA_SUCCESS'
export const WIDGET_FIELD_CHANGED = 'WIDGET_FIELD_CHANGED'
export const WIDGET_RULE_VALUE_CHANGED = 'WIDGET_RULE_VALUE_CHANGED'
export const ADD_WIDGET_RULE = 'ADD_WIDGET_RULE'
export const SAVE_WIDGET_RULE = 'SAVE_WIDGET_RULE'
export const SAVE_WIDGET_RULES_COMPLETE = 'SAVE_WIDGET_RULES_COMPLETE'
export const SAVE_WIDGET_RULES_FAILED = 'SAVE_WIDGET_RULES_FAILED'
export const WIDGET_RULE_LABEL_CHANGED = 'WIDGET_RULE_LABEL_CHANGED'
export const DELETE_WDGET_RULE = 'DELETE_WDGET_RULE'
export const WIDGET_NAME_CHANGED = 'WIDGET_NAME_CHANGED'
export const WIDGET_SHOWCOUNT_CHANGED = 'WIDGET_SHOWCOUNT_CHANGED'
export const WIDGET_TYPE_CHANGED = 'WIDGET_TYPE_CHANGED'
export const WIDGET_ACTION_ON_CHANGED = 'WIDGET_ACTION_ON_CHANGED'
export const LOADING_FOLDER_WIDGET_LIST = 'LOADING_FOLDER_WIDGET_LIST'
export const LOAD_FOLDER_WIDGET_LIST = 'LOAD_FOLDER_WIDGET_LIST'
export const LOAD_FOLDER_WIDGET_LIST_SUCCESS = 'LOAD_FOLDER_WIDGET_LIST_SUCCESS'
export const CLEAN_WIDGET_RULES_DATA = 'CLEAN_WIDGET_RULES_DATA'
export const CHANGE_SELECTED_WIDGET_ID = 'CHANGE_SELECTED_WIDGET_ID'
export const DELETE_FOLDER_WIDGET = 'DELETE_FOLDER_WIDGET'
export const DELETING_FOLDER_WIDGET = 'DELETING_FOLDER_WIDGET'
export const DELETING_FOLDER_WIDGET_SUCCESS = 'DELETING_FOLDER_WIDGET_SUCCESS'
export const BAR_WIDGET_GROUP_SELECT_VALUE_CHANGED = 'BAR_WIDGET_GROUP_SELECT_VALUE_CHANGED'
export const BAR_WIDGET_NUMBER_INPUT_CHANGED = 'BAR_WIDGET_NUMBER_INPUT_CHANGED'
export const BAR_GRID_SELECT_VALUE_CHANGED = 'BAR_GRID_SELECT_VALUE_CHANGED'
export const BAR_WIDGET_RULE_LEBEL_CHANGED = 'BAR_WIDGET_RULE_LEBEL_CHANGED'
export const ADD_BAR_WIDGET_RULE = 'ADD_BAR_WIDGET_RULE'
export const BAR_WIDGET_RULE_DELETE = 'BAR_WIDGET_RULE_DELETE'
export const ADD_BAR_WIDGET_RULE_GROUP = 'ADD_BAR_WIDGET_RULE_GROUP'
export const BAR_WIDGET_RULE_GROUP_DELETE = 'BAR_WIDGET_RULE_GROUP_DELETE'
export const BAR_SHOW_REST_CHECK_BOX_CHANGED = 'BAR_SHOW_REST_CHECK_BOX_CHANGED'
export const BAR_WIDGET_GROUP_BY_DATE_SELECT_VALUE_CHANGED = "BAR_WIDGET_GROUP_BY_DATE_SELECT_VALUE_CHANGED"
export const WIDGET_COLOR_VALUE_CHANGED = "WIDGET_COLOR_VALUE_CHANGED"