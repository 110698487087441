import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { isUserAuthenticatedByMetafourAuth } from '../helpers/authUtils';
import ls from 'local-storage';
import { useLocation } from 'react-router-dom'

export const PrivateRoute = ({ component: Component, roles, ...rest }) => (
    <Route
        {...rest}
        render={(props) => {
            if (!isUserAuthenticatedByMetafourAuth()) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />;
            }

            // const loggedInUser = getLoggedInUser();
            // check if route is restricted by role
            // if (roles && roles.indexOf(loggedInUser.role) === -1) {
            // role not authorised so redirect to home page
            // return <Redirect to={{ pathname: '/' }} />;
            // }
            let MyComp = '';
            switch (Component) {
                case 'RootComponent':
                    if (ls.get('defaultPage') != null) {
                        return <Redirect to={`${ls.get('defaultPage')}`} />;
                    } else {
                        return <Redirect to="/2/90000004" />;
                    }
                case 'StandardLayout':
                    MyComp = React.lazy(() => import('../pages/StandardLayout'));
                    break;
                case 'ProfitLoss':
                    MyComp = React.lazy(() => import('../components/ProfitAndLossWidget'));
                    break;
                case 'Nodepad':
                    MyComp = React.lazy(() => import('../components/NotePadWidget'));
                    break;
                case 'WatchdJobs':
                    MyComp = React.lazy(() => import('../components/WatchedJobsWidget'));
                    break;
                case 'SameDayWidget':
                    MyComp = React.lazy(() => import('../components/SameDayWidget'));
                    break;
                case 'OperationalHoursWidget':
                    MyComp = React.lazy(() => import('../components/OperationalHoursWidget'));
                    break;
                case 'AsyncTasks':
                    MyComp = React.lazy(() => import('../components/TasksInProgressWidget'));
                    break;
                case 'ClientsOnStopWidget':
                    MyComp = React.lazy(() => import('../components/ClientsOnStopWidget'));
                    break;
                case 'Folder':
                    MyComp = React.lazy(() => import('../pages/Folder'));
                    break;
                case 'ListView':
                    MyComp = React.lazy(() => import('../pages/ListView'));
                    break;
                case 'DetailsView':
                    MyComp = React.lazy(() => import('../pages/DetailsView'));
                    break;
                case 'RoleMaintanence':
                    MyComp = React.lazy(() => import('../pages/RoleMaintanence'));
                    break;
                case 'RedirectTo':
                    MyComp = React.lazy(() => import('../components/RedirectTo'));
                    break;
                case 'FolderWidgetSummary':
                    MyComp = React.lazy(() => import('../components/FolderWidgetSummary'));
                    break;
                case 'FolderWidgetPie':
                    MyComp = React.lazy(() => import('../components/FolderWidgetPie'));
                    break;
                case 'FolderWidgetBar':
                    MyComp = React.lazy(() => import('../components/FolderWidgetBar'));
                    break;
                case 'FolderWidgetTable':
                    MyComp = React.lazy(() => import('../components/FolderWidgetTable'));
                    break;
                default:
                    if (ls.get('defaultPage') != null) {
                        return <Redirect to={`${ls.get('defaultPage')}`} />;
                    } else {
                        return <Redirect to="/2/90000004" />;
                    }
            }

            return <MyComp {...props} />;
            // authorised so return component
        }}
    />
);

export const OpenRoute = ({ component: Component, roles, ...rest }) => (
    <Route
        {...rest}
        render={(props) => {
            let MyComp = '';
            switch (Component) {
                case 'Login':
                    MyComp = React.lazy(() => import('../pages/auth/Login'));
                    break;
                case 'Logout':
                    MyComp = React.lazy(() => import('../pages/auth/Logout'));
                    break;
                default:
                    MyComp = React.lazy(() => import('../pages/auth/Login'));
                    break;
            }
            return <MyComp {...props} />;
            // authorised so return component
        }}
    />
);

// root routes
const rootRoute = {
    path: '/',
    exact: true,
    component: () => <Redirect to="/2/90000004" />,
    route: PrivateRoute,
};

// auth
const authRoutes = {
    path: '/account',
    name: 'Auth',
    children: [
        {
            path: '/account/login',
            name: 'Login',
            component: 'Login',
            route: 'Route',
        },
        {
            path: '/account/logout',
            name: 'Logout',
            component: 'Logout',
            route: 'Route',
        },
    ],
};

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach((item) => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};
// All routes
const allRoutes = [rootRoute, authRoutes];

const authProtectedRoutes = [];

const authroutes = flattenRoutes(allRoutes);
export { allRoutes, authProtectedRoutes, authroutes, flattenRoutes };
