/* LAYOUT */
export const CHANGE_SELECTED_ROW = 'CHANGE_SELECTED_ROW';
export const IS_DROPDOWN_OPEN = 'IS_DROPDOWN_OPEN';
export const CHANGE_IFRAM_LOAD_STATUS = 'CHANGE_IFRAM_LOAD_STATUS';
export const ALL_SELECTED_ROWS = 'ALL_SELECTED_ROWS';
export const PREVIEW_PANEL_STATUS = 'PREVIEW_PANEL_STATUS';
export const DO_REFRESH_LIST = 'DO_REFRESH_LIST';
export const GROUP_ACTION_STATUS_MESSAGE = 'GROUP_ACTION_STATUS_MESSAGE';
export const SHOW_IFRAME_MASK_ON_DRAG = 'SHOW_IFRAME_MASK_ON_DRAG';
export const SHOW_HIDE_ADVANCE_SEARCH_CONTROL = 'SHOW_HIDE_ADVANCE_SEARCH_CONTROL';
export const CHANGE_TABLE_FILTER = 'CHANGE_TABLE_FILTER';
