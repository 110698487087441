// @flow
import {
    CHANGE_SELECTED_ROW,
    IS_DROPDOWN_OPEN,
    CHANGE_IFRAM_LOAD_STATUS,
    ALL_SELECTED_ROWS,
    PREVIEW_PANEL_STATUS,
    DO_REFRESH_LIST,
    GROUP_ACTION_STATUS_MESSAGE,
    SHOW_IFRAME_MASK_ON_DRAG,
    SHOW_HIDE_ADVANCE_SEARCH_CONTROL,
    CHANGE_TABLE_FILTER,
} from './constants';

type LayoutAction = { type: string, payload?: [] | null };

export const changeSelectedRow = (rowvalue: [], actionUrl: String): LayoutAction => ({
    type: CHANGE_SELECTED_ROW,
    payload: { row: rowvalue, actionUrl: actionUrl },
});

export const dropDownStateChange = (isOpen: Boolean): ActionDropDown => ({
    type: IS_DROPDOWN_OPEN,
    payload: isOpen,
});

export const changeIframLoadStatus = (isIframLoading: Boolean): LayoutAction => ({
    type: CHANGE_IFRAM_LOAD_STATUS,
    payload: isIframLoading,
});

export const allSelectedRows = (allRows: []): LayoutAction => ({
    type: ALL_SELECTED_ROWS,
    payload: allRows,
});

export const changePreviewPanelStatus = (previewPanStatus: Boolean): LayoutAction => ({
    type: PREVIEW_PANEL_STATUS,
    payload: previewPanStatus,
});

export const doRefreshList = (shouldRefreshList: Boolean): LayoutAction => ({
    type: DO_REFRESH_LIST,
    payload: shouldRefreshList,
});

export const groupActionStatusAndMessage = (show: Boolean, status: Boolean, message: string): ComponentAction => ({
    type: GROUP_ACTION_STATUS_MESSAGE,
    payload: { showGroupActionStatus: show, groupActionStatus: status, groupActionMessage: message },
});

export const showIfameMaskOnDrag = (showIframeMask: Boolean): LayoutAction => ({
    type: SHOW_IFRAME_MASK_ON_DRAG,
    payload: showIframeMask,
});

export const toggleAdvanceSearch = (show: Boolean): LayoutAction => ({
    type: SHOW_HIDE_ADVANCE_SEARCH_CONTROL,
    payload: show,
});

export const changeTableFilter = filterId => ({
    type: CHANGE_TABLE_FILTER,
    payload: filterId
})
