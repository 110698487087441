// @flow
import {
    CHANGE_SELECTED_ROW,
    IS_DROPDOWN_OPEN,
    CHANGE_IFRAM_LOAD_STATUS,
    ALL_SELECTED_ROWS,
    PREVIEW_PANEL_STATUS,
    DO_REFRESH_LIST,
    GROUP_ACTION_STATUS_MESSAGE,
    SHOW_IFRAME_MASK_ON_DRAG,
    SHOW_HIDE_ADVANCE_SEARCH_CONTROL,
    CHANGE_TABLE_FILTER,
} from './constants';

const INIT_STATE = {
    selectedRow: [],
    iframeLoading: false,
    actionDropDownState: '',
    selectedRows: [],
    previewPanStatus: false,
    shouldRefreshList: false,
    showGroupActionStatus: false,
    groupActionStatus: false,
    groupActionMessage: '',
    showIframMask: false,
    actionUrl: [],
    showAdvanceSearchControl: false,
};

type RowAction = { type: string, payload: {} | string };

const StandardLayout = (state: State = INIT_STATE, action: RowAction) => {
    switch (action.type) {
        case CHANGE_SELECTED_ROW:
            return {
                ...state,
                selectedRow: action.payload.row,
                actionUrl: action.payload.actionUrl,
            };
        case CHANGE_IFRAM_LOAD_STATUS:
            return {
                ...state,
                iframeLoading: action.payload,
            };
        case IS_DROPDOWN_OPEN:
            return {
                ...state,
                actionDropDownState: action.payload,
            };
        case ALL_SELECTED_ROWS:
            return {
                ...state,
                selectedRows: action.payload,
            };
        case PREVIEW_PANEL_STATUS:
            return {
                ...state,
                previewPanStatus: action.payload,
            };
        case DO_REFRESH_LIST:
            return {
                ...state,
                shouldRefreshList: action.payload,
            };
        case SHOW_IFRAME_MASK_ON_DRAG:
            return {
                ...state,
                showIframeMask: action.payload,
            };
        case GROUP_ACTION_STATUS_MESSAGE:
            return {
                ...state,
                groupActionStatus: action.payload.groupActionStatus,
                groupActionMessage: action.payload.groupActionMessage,
                showGroupActionStatus: action.payload.showGroupActionStatus,
            };
        case SHOW_HIDE_ADVANCE_SEARCH_CONTROL:
            return {
                ...state,
                showAdvanceSearchControl: action.payload,
            };

        case CHANGE_TABLE_FILTER:
            return {
                ...state,
                myFilterId: action.payload
            };

        default:
            return state;
    }
};

export default StandardLayout;
