import { resizeLeftSideBar } from './helpers/utils';

export function register(config) {
    if ('serviceWorker' in navigator) {
        const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

        window.addEventListener('load', () => {
            navigator.serviceWorker.register(swUrl).then((registration) => {
                console.log('Service Worker registered.');

                // Cache busting by updating cache when a new version is available
                registration.onupdatefound = () => {
                    const installingWorker = registration.installing;
                    if (installingWorker == null) {
                        return;
                    }
                    installingWorker.onstatechange = () => {
                        if (installingWorker.state === 'installed') {
                            if (navigator.serviceWorker.controller) {
                                // New content available, update cache
                                console.log('New content available, updating cache.');
                                registration.update();
                            }
                        }
                    };
                };

                if (config && config.onSuccess) {
                    config.onSuccess(registration);
                }
            }).catch((error) => {
                console.error('Error during service worker registration:', error);
            });
        });
    }

    // Additional event listeners
    window.addEventListener('scroll', function (e) {
        resizeLeftSideBar();
    });

    document.addEventListener('click', function (event) {
        var e = event || window.event,
            target = e.target || e.srcElement;
        if (target.tagName.toUpperCase() == 'INPUT') {
            event.target.select();
        }
    });
}

export function unregister() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then((registration) => {
            registration.unregister();
        });
    }
}
