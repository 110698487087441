import {
    OPEN_MODAL,
    CLOSE_MODAL,
    START_KEY_BINDING,
    SET_KEY_BINDING,
    SAVE_SHORTCUTS,
    GET_TABLE_SHORTCUT,
    SET_QUICK_ACTIONS_SHORTCUT,
    SEARCH_HOTKEY,
    SAVE_SHORTCUTS_COMPLETE,
    SET_CUSTOM_TABLE_SHORTCUTS,
    GET_ALL_QUICK_ACTIONS_SHORTCUT,
    COPY_SHORTCUTS,
    SHOW_LOADER,
    UNBOUND_KEY
} from './constants'
import cloneDeep from 'lodash/cloneDeep';
import Fuse from 'fuse.js';

const INIT_STATE = {
    showLoaderState: false,
    allQuickActionsShotcuts: [],
    success: null,
    message: null,
    tableFunctionsLableAndTooltips: [{
        action: "jump_to_search",
        label: "Jump to search",
        tooltip: "Focus on the quick search in the table header"
    },
    {
        action: "singleRowSelect",
        label: "Select & focus row",
        tooltip: "Select & focus row"
    },
    {
        action: "singleRowSelectAndOpenActionMenu",
        label: "Show actions",
        tooltip: "Select & focus this row. Open the actions menu for this row"
    },
    {
        action: "scrollUp",
        label: "Up",
        tooltip: "Focus Previous row. Or Previous action menu"
    },
    {
        action: "scrollDown",
        label: "Down",
        tooltip: "Focus Next row. Or Next action menu"
    },
    {
        action: "scrollRight",
        label: "Right",
        tooltip: "Scroll rows right <br /> Open sub action menu link and focus 1st link within"
    },
    {
        action: "scrollLeft",
        label: "Left",
        tooltip: "Scroll rows left <br /> Close current sub action menu and focus parent <br /> Close action menu, if currently focused on top level link"
    },
    {
        action: "gotoNextPage",
        label: "Next page",
        tooltip: "Next page"
    },
    {
        action: "gotoPrevPage",
        label: "Previous page",
        tooltip: "Previous page"
    },
    {
        action: "randomSelectRows",
        label: "Select and focus this row",
        tooltip: "Useful for selecting rows that are not next to one another"
    },
    {
        action: "batchSelectRows",
        label: "Batch select",
        tooltip: "Select all rows between the previously selected row and this one."
    },
    {
        action: "batchSelectRowsAndOpenActionMenu",
        label: "Batch select & Open batch action menu",
        tooltip: "Select all rows between the previously selected row and this one. Open actions menu at the clicked location."
    },
    {
        action: "toggleActionMenuOnEnter",
        label: "Open action menu",
        tooltip: "Toggle action menu for this row menu."
    },
    {
        action: "randomSelectRowsAndOpenActionMenu",
        label: "Open batch action menu for random selection",
        tooltip: "Toggle batch action menu for the selected rows"
    },
    {
        action: "selectPrevRow",
        label: "Select previous row",
        tooltip: "Select and focus the previous row."
    },
    {
        action: "selectNextRow",
        label: "Select next row",
        tooltip: "Select current row, if not already selected, Select and focus the next row."
    },
    {
        action: "toggleRowSelectionAndSelectContextMenuOption",
        label: "Focus row",
        tooltip: "Toggle selection on the focussed row <br /> Select context menu option"
    },
    {
        action: "hideContextMenu",
        label: "Close menu",
        tooltip: "Close action menu. Focus the last selected row"
    },
    {
        action: "selectAllRows",
        label: "Select All",
        tooltip: "Select all rows if the current page"
    }],
    modalOpen: false,
    tableShortcuts: [],
    tableCustomShortcut: [],
    tempTableShortcuts: [],
    userHasCustomShortcut: false,
    quickActionShotcuts: [],
    tableDefaultShortcuts: [{
        key: "alt+f",
        action: "jump_to_search",
        label: "Jump to search",
        tooltip: "Focus on the quick search in the table header"
    },
    {
        key: "LeftClick",
        action: "singleRowSelect",
        label: "Select & focus row",
        tooltip: "Select & focus row"
    },
    {
        key: "RightClick",
        action: "singleRowSelectAndOpenActionMenu",
        label: "Show actions",
        tooltip: "Select & focus this row. Open the actions menu for this row"
    },
    {
        key: "ArrowUp",
        action: "scrollUp",
        label: "Up",
        tooltip: "Focus Previous row. Or Previous action menu"
    },
    {
        key: "ArrowDown",
        action: "scrollDown",
        label: "Down",
        tooltip: "Focus Next row. Or Next action menu"
    },
    {
        key: "ArrowRight",
        action: "scrollRight",
        label: "Right",
        tooltip: "Scroll rows right <br /> Open sub action menu link and focus 1st link within"
    },
    {
        key: "ArrowLeft",
        action: "scrollLeft",
        label: "Left",
        tooltip: "Scroll rows left <br /> Close current sub action menu and focus parent <br /> Close action menu, if currently focused on top level link"
    },
    {
        key: "ctrl+ArrowRight",
        action: "gotoNextPage",
        label: "Next page",
        tooltip: "Next page"
    },
    {
        key: "ctrl+ArrowLeft",
        action: "gotoPrevPage",
        label: "Previous page",
        tooltip: "Previous page"
    },
    {
        key: "ctrl+LeftClick",
        action: "randomSelectRows",
        label: "Select and focus this row",
        tooltip: "Useful for selecting rows that are not next to one another"
    },
    {
        key: "shift+LeftClick",
        action: "batchSelectRows",
        label: "Batch select",
        tooltip: "Select all rows between the previously selected row and this one."
    },
    {
        key: "shift+RightClick",
        action: "batchSelectRowsAndOpenActionMenu",
        label: "Batch select & Open batch action menu",
        tooltip: "Select all rows between the previously selected row and this one. Open actions menu at the clicked location."
    },
    {
        key: "Enter",
        action: "toggleActionMenuOnEnter",
        label: "Open action menu",
        tooltip: "Toggle action menu for this row menu."
    },
    {
        key: "ctrl+Enter",
        action: "randomSelectRowsAndOpenActionMenu",
        label: "Open batch action menu for random selection",
        tooltip: "Toggle batch action menu for the selected rows"
    },
    {
        key: "ctrl+ArrowUp",
        action: "selectPrevRow",
        label: "Select previous row",
        tooltip: "Select and focus the previous row."
    },
    {
        key: "ctrl+ArrowDown",
        action: "selectNextRow",
        label: "Select next row",
        tooltip: "Select current row, if not already selected, Select and focus the next row."
    },
    {
        key: "Space",
        action: "toggleRowSelectionAndSelectContextMenuOption",
        label: "Focus row",
        tooltip: "Toggle selection on the focussed row <br /> Select context menu option"
    },
    {
        key: "Escape",
        action: "hideContextMenu",
        label: "Close menu",
        tooltip: "Close action menu. Focus the last selected row"
    },
    {
        key: "ctrl+a",
        action: "selectAllRows",
        label: "Select All",
        tooltip: "Select all rows if the current page"
    }]
};

type ModalAction = { type: string, payload: {} | string };
const ShortcutSetup = (state: State = INIT_STATE, action: ModalAction) => {

    switch (action.type) {
        case SET_CUSTOM_TABLE_SHORTCUTS:
            if (action.payload?.length < 1) {
                return {
                    ...state,
                    tableCustomShortcut: cloneDeep(state.tableDefaultShortcuts),
                    tableShortcuts: cloneDeep(state.tableDefaultShortcuts)
                }
            }
            let keysObj = [];
            action.payload.map((item) => {
                let label = "";
                state.tableFunctionsLableAndTooltips.filter((s) => s.action === item.action).map((item) => { label = item.label })

                keysObj.push({ key: item.key, action: item.action, label: label })
            })
            return {
                ...state,
                tableCustomShortcut: keysObj,
                tableShortcuts: keysObj
            }

        case GET_ALL_QUICK_ACTIONS_SHORTCUT:
            const allQuickActions = [];
            action.payload.actionShortcuts.filter((item) => item.action.toUpperCase() !== "DEFAULT" && item.action.toUpperCase() !== "VIEW").map((item) => {
                allQuickActions.push({ key: item.key, action: item.action, label: item.action })
            })

            return {
                ...state,
                allQuickActionsShotcuts: allQuickActions
            }

        case SET_QUICK_ACTIONS_SHORTCUT:
            const quickActions = [];
            action.payload.actionShortcuts.filter((item) => item.actionId).map((item) => {

                quickActions.push({ key: item.hotkey, action: item.actionId, label: item.title })
            })

            return {
                ...state,
                quickActionShotcuts: quickActions
            }

        case GET_TABLE_SHORTCUT:
            var tableCustomShortcutVar = [];
            state.tableCustomShortcut.map((item) => {
                tableCustomShortcutVar.push(item);
            })

            var tableDefaultShortcutsVar = [];
            state.tableDefaultShortcuts.map((item) => {
                tableDefaultShortcutsVar.push(item);
            })

            if (state.tableCustomShortcut.length < 1) {
                return {
                    ...state,
                    userHasCustomShortcut: false,
                    tableShortcuts: cloneDeep(tableDefaultShortcutsVar),
                    tableCustomShortcut: cloneDeep(tableDefaultShortcutsVar)
                }
            } else {
                return {
                    ...state,
                    userHasCustomShortcut: true,
                    tableShortcuts: cloneDeep(tableCustomShortcutVar),
                    tableCustomShortcut: cloneDeep(tableCustomShortcutVar)
                }
            }

        case OPEN_MODAL:
            var tableCustomShortcutVar = [];
            state.tableCustomShortcut.map((item) => {
                tableCustomShortcutVar.push(item);
            })

            var tableDefaultShortcutsVar = [];
            state.tableDefaultShortcuts.map((item) => {
                tableDefaultShortcutsVar.push(item);
            })

            if (state.tableCustomShortcut.length < 1) {
                return {
                    ...state,
                    modalOpen: true,
                    success: null,
                    userHasCustomShortcut: false,
                    tableShortcuts: cloneDeep(tableDefaultShortcutsVar),
                    tableCustomShortcut: cloneDeep(tableDefaultShortcutsVar)
                }
            } else {
                return {
                    ...state,
                    modalOpen: true,
                    userHasCustomShortcut: true,
                    tableShortcuts: cloneDeep(tableCustomShortcutVar),
                    tableCustomShortcut: cloneDeep(tableCustomShortcutVar)
                }
            }

        case CLOSE_MODAL:
            return {
                ...state,
                success: null,
                modalOpen: false,
                tableCustomShortcut: cloneDeep(state.tableShortcuts)
            }
        case SAVE_SHORTCUTS:
            return {
                ...state, //copying the orignal state
                tableShortcuts: cloneDeep(state.tableCustomShortcut)
            }
        case SAVE_SHORTCUTS_COMPLETE:
            return {
                ...state, //copying the orignal state
                success: action.payload.status ? 'OK' : 'FAILED',
                message: action.payload.message
            }
        case START_KEY_BINDING:
            const tableShortcutArr = [...state.tableCustomShortcut]; //making a new array
            const actionShortcutArr = [...state.allQuickActionsShotcuts]; //making a new array
            if (action.payload.type === "T") {
                tableShortcutArr[action.payload.index].key = "Bind new key"//changing value in the new array
            } else {
                actionShortcutArr[action.payload.index].key = "Bind new key"//changing value in the new array
            }
            return {
                ...state, //copying the orignal state
                tableCustomShortcut: tableShortcutArr, //reassingning todos to new array
                allQuickActionsShotcuts: actionShortcutArr
            }
        case SET_KEY_BINDING:
            const tableShortcutArr1 = [...state.tableCustomShortcut]; //making a new array
            const actionShortcutArr1 = [...state.allQuickActionsShotcuts]; //making a new array

            tableShortcutArr1.filter((item) => item.key === action.payload.key).map((item, index) => {
                item.key = "NOKEY"
            })
            actionShortcutArr1.filter((item) => item.key === action.payload.key).map((item, index) => {
                item.key = "NOKEY"
            })
            if (action.payload.type === "T") {
                tableShortcutArr1[action.payload.index].key = action.payload.key //changing value in the new array
            } else {
                actionShortcutArr1[action.payload.index].key = action.payload.key //changing value in the new array
            }

            return {
                ...state, //copying the orignal state
                tableCustomShortcut: tableShortcutArr1, //reassingning todos to new array
                allQuickActionsShotcuts: actionShortcutArr1
            }
        case SHOW_LOADER:
            return {
                ...state,
                showLoaderState: action.payload

            }
        case SEARCH_HOTKEY:
            const tableShortcutArr2 = [...state.tableCustomShortcut]; //making a new array
            const actionShortcutArr2 = [...state.allQuickActionsShotcuts]; //making a new array
            tableShortcutArr2.map((item) => {
                item.foundInSearch = false;
                item.refIndex = null
            })
            actionShortcutArr2.map((item) => {
                item.foundInSearch = false;
                item.refIndex = null;
            })
            if (action.payload !== "") {

                const fuse = new Fuse(tableShortcutArr2, {
                    keys: ['label'],
                    includeMatches: true,
                    threshold: 0.2,
                });
                const results = fuse.search(action.payload);
                results.map((result) => {
                    tableShortcutArr2[result.refIndex].foundInSearch = true;
                    tableShortcutArr2[result.refIndex].refIndex = result.refIndex
                });

                const fuse1 = new Fuse(actionShortcutArr2, {
                    keys: ['label'],
                    includeMatches: true,
                    threshold: 0.2,
                });
                const results1 = fuse1.search(action.payload);
                results1.map((results1) => {
                    actionShortcutArr2[results1.refIndex].foundInSearch = true
                    actionShortcutArr2[results1.refIndex].refIndex = results1.refIndex
                });
            }


            return {
                ...state, //copying the orignal state
                tableCustomShortcut: tableShortcutArr2, //reassingning todos to new array
                allQuickActionsShotcuts: actionShortcutArr2,
                showLoaderState: false
            }
        case COPY_SHORTCUTS:
            const tableCustomShortcut = [...state.tableCustomShortcut]; //making a new array
            const allQuickActionsShotcuts = [...state.allQuickActionsShotcuts]; //making a new array
            tableCustomShortcut.map((item) => {
                action.payload.filter((fitem) => fitem.action === item.action).map((fitem) => {
                    item.key = fitem.key;
                })
            })

            allQuickActionsShotcuts.map((item) => {
                action.payload.filter((fitem) => fitem.action === item.action).map((fitem) => {
                    item.key = fitem.key;
                })
            })
            return {
                ...state, //copying the orignal state
                tableCustomShortcut: tableCustomShortcut, //reassingning todos to new array
                allQuickActionsShotcuts: allQuickActionsShotcuts
            }

        case UNBOUND_KEY:
            // alert(action.payload.existingKey);
            const tableShortcutArr3 = [...state.tableCustomShortcut]; //making a new array
            const actionShortcutAr3 = [...state.allQuickActionsShotcuts]; //making a new array
            if (action.payload.existingKey == null || action.payload.existingKey === "NOKEY") {
                if (action.payload.type === "T") {
                    tableShortcutArr3[action.payload.index].key = "NOKEY"//changing value in the new array
                } else {
                    actionShortcutAr3[action.payload.index].key = "NOKEY"//changing value in the new array
                }
            } else {
                if (action.payload.type === "T") {
                    tableShortcutArr3[action.payload.index].key = action.payload.existingKey//changing value in the new array
                } else {
                    actionShortcutAr3[action.payload.index].key = action.payload.existingKey//changing value in the new array
                }
            }

            return {
                ...state, //copying the orignal state
                tableCustomShortcut: tableShortcutArr3, //reassingning todos to new array
                allQuickActionsShotcuts: actionShortcutAr3
            }


        default:
            return state
    }
}


export default ShortcutSetup