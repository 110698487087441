// @flow
import { EXPAND_RIGHT_SIDEBAR } from '../layout/constants';
import {
    RIGHT_BAR_STATE,

} from './constants';

const INIT_STATE = {
    rightBarState: 'test',
};

type RightBarAction = { type: string, payload: {} | string };
type State = { user?: {} | null, loading?: boolean, +value?: boolean };

const RightBar = (state: State = INIT_STATE, action: RightBarAction) => {
    switch (action.type) {
        case RIGHT_BAR_STATE:
            return { ...state, rightBarState: action.payload };
        case EXPAND_RIGHT_SIDEBAR:
            return {
                ...state,
                expandState: action.payload
            };
        default:
            return { ...state };
    }
};

export default RightBar;
