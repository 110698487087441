// @flow
import {
    RIGHT_BAR_STATE
} from './constants';

type LayoutAction = { type: string, payload?: string | null };


export const rightBarStateChange = (expandType: string): LayoutAction => ({
    type: RIGHT_BAR_STATE,
    payload: expandType,
});