import { groupBy } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import {
    FOLDER_MAINTENANCE_OPENED,
    FOLDER_NAME_CHANGED,
    LOADING_FOLDER_RULES_DATA,
    LOAD_FOLDER_RULES_DATA_SUCCESS,
    PARENT_FOLDER_CHANGED,
    ACCESS_GRANTED_TO_CHANGED,
    REFRESH_TABLE_AT_INTERVAL_CHANGED,
    DETAILS_URI_CHANGED,
    LIST_URI_CHANGED,
    OBJECT_TYPE_CHANGED,
    TABLE_ACCESSED_CHANGED,
    SET_FIRST_FILTER_BY_DEFAULT_CHANGED,
    SET_FIRST_ROW_BY_DEFAULT_CHANGED,
    PREVIEW_WINDOW_CHANGED,
    LOADING_COLUMN_RULES_DATA,
    LOAD_COLUMN_RULES_DATA_SUCCESS,
    COLUMNS_TO_SHOW_CHANGED,
    DEFAULT_SORT_CHANGED,
    SORT_DIRECTION_CHANGED,
    HANDLE_SORT_ORDER_CHANGED,
    HANDLE_COLUMN_ALIAS_CHANGED,
    LOADING_SELECTION_RULES_DATA,
    LOAD_SELECTION_RULES_DATA_SUCCESS,
    ADD_SELECTION_RULE,
    DELETE_SELECTION_RULE,
    MOVE_UP_SELECTION_RULE,
    MOVE_DOWN_SELECTION_RULE,
    LOADING_ACTION_RULES_DATA,
    LOAD_ACTION_RULES_DATA_SUCCESS,
    ADD_ACTION_RULE,
    MOVE_UP_ACTION_RULE,
    MOVE_DOWN_ACTION_RULE,
    DELETE_ACTION_RULE,
    SELECTION_TYPE_CHANGED,
    SELECTION_FIELD_CHANGED,
    SELECTION_TEST_CHANGED,
    SELECTION_VALUE_CHANGED,
    SELECTION_FOREGROUND_CHANGED,
    SELECTION_BACKGROUND_CHANGED,
    CLEAN_UP_DATA,
    SAVE_FOLDER_COMPLETE,
    SAVE_FOLDER_FAILED,
    TRIGGER_FORM_SUBMITTED,
    GRID_SELECT_VALUE_CHANGED,
    GRID_INPUT_VALUE_CHANGED,
    SAVING_FOLDER,
    SEQUENCE_NUMBER_CHANGED,
    RESULT_PER_PAGE_CHANGED,
    LOAD_WIDGET_RULES_DATA_SUCCESS,
    WIDGET_FIELD_CHANGED,
    WIDGET_RULE_VALUE_CHANGED,
    ADD_WIDGET_RULE,
    SAVE_WIDGET_RULES_COMPLETE,
    SAVE_WIDGET_RULES_FAILED,
    WIDGET_RULE_LABEL_CHANGED,
    DELETE_WDGET_RULE,
    WIDGET_NAME_CHANGED,
    WIDGET_SHOWCOUNT_CHANGED,
    WIDGET_TYPE_CHANGED,
    WIDGET_ACTION_ON_CHANGED,
    LOAD_FOLDER_WIDGET_LIST_SUCCESS,
    CLEAN_WIDGET_RULES_DATA,
    LOADING_WIDGET_RULES_DATA,
    CHANGE_SELECTED_WIDGET_ID,
    BAR_WIDGET_GROUP_SELECT_VALUE_CHANGED,
    BAR_WIDGET_NUMBER_INPUT_CHANGED,
    BAR_GRID_SELECT_VALUE_CHANGED,
    BAR_WIDGET_RULE_LEBEL_CHANGED,
    ADD_BAR_WIDGET_RULE,
    BAR_WIDGET_RULE_DELETE,
    ADD_BAR_WIDGET_RULE_GROUP,
    BAR_WIDGET_RULE_GROUP_DELETE,
    BAR_SHOW_REST_CHECK_BOX_CHANGED,
    BAR_WIDGET_GROUP_BY_DATE_SELECT_VALUE_CHANGED,
    DELETING_FOLDER_WIDGET,
    DELETING_FOLDER_WIDGET_SUCCESS,
    WIDGET_COLOR_VALUE_CHANGED,
    FOLDER_SELECT_FROM_CHANGED,
    FOLDER_SELECT_TO_NAME_CHANGED
} from './constants';
import { processData, processWidgetData } from '../../helpers/utils';

const Folder = (
    state = {
        folderRulesLoaded: false,
        isFolderRulesLoading: false,
        columnRulesLoaded: false,
        isColumnRulesLoading: false,
        selectionRulesLoaded: false,
        isSelectionRulesLoading: false,
        actionRulesLoaded: false,
        isActionRulesLoading: false,
        isFormSubmitted: false,
        savingFolder: false,
        success: 'NOT_INITIALIZED',
        widgetDeleted: false,
        columnRules: {
            data: {
                columnsToShow: {
                    selected: [],
                },
            },
        },
    },
    action
) => {
    var target = null;
    var selected = null;
    var item = null;
    var rule = null;

    switch (action.type) {
        case FOLDER_MAINTENANCE_OPENED:
            return { ...state, folderId: action.payload };

        case LOADING_FOLDER_RULES_DATA:
            return { ...state, isFolderRulesLoading: true };

        case LOAD_FOLDER_RULES_DATA_SUCCESS:
            processData(action.payload.data);
            target = { ...state, isFolderRulesLoading: false, folderRulesLoaded: true, folderRules: action.payload };
            if (target?.folderRules?.data?.objectType?.selected?.length > 0) {
                target.folderRules.data.objectType.selected[0].tableId =
                    target.folderRules.data.objectTypeMap.objects[
                        target.folderRules.data.objectType.selected[0].value
                    ]?.key;
            }
            return target;

        case LOADING_COLUMN_RULES_DATA:
            return { ...state, isColumnRulesLoading: true };

        case LOAD_COLUMN_RULES_DATA_SUCCESS:
            action.payload.data.defaultSort.options = action.payload.data.columnsToShow.options;
            action.payload.data.defaultSort.selected = [action.payload.data.columnsToShow.values[0]];
            processData(action.payload.data);
            return { ...state, isColumnRulesLoading: false, columnRulesLoaded: true, columnRules: action.payload };

        case LOADING_SELECTION_RULES_DATA:
            return { ...state, isSelectionRulesLoading: true };

        case LOAD_SELECTION_RULES_DATA_SUCCESS:
            action.payload.data.forEach((dta) => {
                let cols = [];
                Object.entries(dta.fcolumn.options).forEach(([key, input]) => {
                    cols.push({ label: input.alias, value: input.value })
                })
                dta.fcolumn.options = cols
                processData(dta)
            });
            return {
                ...state,
                isSelectionRulesLoading: false,
                selectionRulesLoaded: true,
                selectionRules: action.payload,
            };

        case LOAD_WIDGET_RULES_DATA_SUCCESS:
            action.payload.data.forEach((dta) => processWidgetData(dta));
            processWidgetData(action.payload.widget);
            const groupFileds = groupBy(action.payload.data, function (n) {
                return n.groupId.value;
            });
            var groups = []
            for (const [key, value] of Object.entries(groupFileds)) {
                let group = [];
                value.map((ob, index) => {
                    group.push(ob);
                })
                groups.push(group);
            }
            return {
                ...state,
                isWidgetRulesLoading: false,
                widgetRulesLoaded: true,
                widgetRules: action.payload,
                widgetRulesByGroup: groups,
                summaryrules: cloneDeep(action.payload.data)
            };

        case LOADING_ACTION_RULES_DATA:
            return { ...state, isActionRulesLoading: true };

        case LOAD_ACTION_RULES_DATA_SUCCESS:
            action.payload.data.forEach((dta) => processData(dta));
            return { ...state, isActionRulesLoading: false, actionRulesLoaded: true, actionRules: action.payload };

        case FOLDER_NAME_CHANGED:
            return {
                ...state,
                folderRules: {
                    ...state.folderRules,
                    data: {
                        ...state.folderRules.data,
                        folderName: {
                            ...state.folderRules.data.folderName,
                            value: action.payload,
                        },
                    },
                },
            };

        case FOLDER_SELECT_FROM_CHANGED:
            return {
                ...state,
                folderRules: {
                    ...state.folderRules,
                    data: {
                        ...state.folderRules.data,
                        selectFrom: {
                            ...state.folderRules.data.selectFrom,
                            value: action.payload,
                        },
                    },
                },
            };

        case FOLDER_SELECT_TO_NAME_CHANGED:
            return {
                ...state,
                folderRules: {
                    ...state.folderRules,
                    data: {
                        ...state.folderRules.data,
                        selectTo: {
                            ...state.folderRules.data.selectTo,
                            value: action.payload,
                        },
                    },
                },
            };

        case PARENT_FOLDER_CHANGED:
            target = { ...state };
            target.folderRules.data.parentFolders.selected = [action.payload];
            return target;

        case ACCESS_GRANTED_TO_CHANGED:
            target = { ...state };
            target.folderRules.data.accessGrantedTo.selected = action.payload;
            return target;

        case REFRESH_TABLE_AT_INTERVAL_CHANGED:

            target = { ...state };
            let minRefreshTime = target.folderRules.data.refreshTableAtInterval.defaultValue;
            let refreshInterval = Math.abs(action.payload?.value);
            if (action.payload.event === 'blur' && refreshInterval > 0 && refreshInterval < minRefreshTime) {
                refreshInterval = minRefreshTime;
            }
            target.folderRules.data.refreshTableAtInterval.value = refreshInterval;
            return target;

        case SEQUENCE_NUMBER_CHANGED:
            target = { ...state };
            target.folderRules.data.sequence.value = Math.abs(action.payload);
            return target;

        case OBJECT_TYPE_CHANGED:
            target = { ...state };
            target.folderRules.data.objectType.selected = [action.payload];
            let table = action.payload.value;
            action.payload.tableId = target.folderRules.data?.objectTypeMap?.objects[table]?.key;
            target.folderRules.data.listId.value = action.payload.tableId ? action.payload.tableId : '';
            target.folderRules.data.detailUri.value = target.folderRules.data?.objectTypeMap?.objects[table]?.detailUri;
            target.folderRules.data.listUri.value = target.folderRules.data?.objectTypeMap?.objects[table]?.listUri;
            selected = target.folderRules.data?.tables?.options.filter((o) => o.label === table);
            let tableVal = selected && selected.length > 0 ? selected[0] : '';
            target.folderRules.data.tables.value = [tableVal];
            target.folderRules.data.tables.selected = [tableVal];
            return target;

        case DETAILS_URI_CHANGED:
            target = { ...state };
            target.folderRules.data.detailUri.value = action.payload;
            return target;

        case LIST_URI_CHANGED:
            target = { ...state };
            target.folderRules.data.listUri.value = action.payload;
            return target;
        case TABLE_ACCESSED_CHANGED:
            target = { ...state };
            target.folderRules.data.tables.selected = [action.payload];
            return target;
        case RESULT_PER_PAGE_CHANGED:
            target = { ...state };
            target.folderRules.data.resultPerPage.selected = [action.payload];
            return target;
        case SET_FIRST_FILTER_BY_DEFAULT_CHANGED:
            target = { ...state };
            target.folderRules.data.selectFirstFilterByDefault.options[0].checked = !(
                target.folderRules.data.selectFirstFilterByDefault.options[0].checked === 'true'
            )
                ? 'true'
                : 'false';
            return target;

        case SET_FIRST_ROW_BY_DEFAULT_CHANGED:
            target = { ...state };
            target.folderRules.data.selectFirstRowByDefault.options[0].checked = !(
                target.folderRules.data.selectFirstRowByDefault.options[0].checked === 'true'
            )
                ? 'true'
                : 'false';
            return target;

        case PREVIEW_WINDOW_CHANGED:
            target = { ...state };
            target.folderRules.data.previewWindow.options.forEach(
                (op) => (op.checked = op.value === action.payload ? 'true' : 'false')
            );
            return target;

        case COLUMNS_TO_SHOW_CHANGED:
            target = { ...state };
            target.columnRules.data.columnsToShow.selected = action.payload;
            let ops = action.payload.slice();
            ops.unshift({ value: '', label: '' });
            target.columnRules.data.defaultSort.options = ops;
            if (
                action.payload?.filter((ob) => ob.value === target.columnRules.data.defaultSort.selected?.value).length === 0
            ) {
                target.columnRules.data.defaultSort.selected = action.payload[0];
            }
            if (action.payload?.length === 0) {
                target.columnRules.data.defaultSort.selected = [];
                target.columnRules.data.defaultSort.value = '';
            }
            return target;

        case DEFAULT_SORT_CHANGED:
            target = { ...state };
            target.columnRules.data.defaultSort.selected = action.payload;
            return target;

        case SORT_DIRECTION_CHANGED:
            target = { ...state };
            target.columnRules.data.sortDirection.options.forEach(
                (op) => (op.checked = op.value === action.payload ? 'true' : 'false')
            );
            return target;

        case HANDLE_SORT_ORDER_CHANGED:
            target = { ...state };
            target.columnRules.data.columnsToShow.selected = action.payload;
            return target;

        case HANDLE_COLUMN_ALIAS_CHANGED:
            target = { ...state };
            target.columnRules.data.columnsToShow.selected = action.payload;
            return target;

        case ADD_SELECTION_RULE:
            target = { ...state };
            rule = Object.assign(
                {},
                JSON.parse(
                    JSON.stringify(
                        target.selectionRulesBackup ? target.selectionRulesBackup : target.selectionRules.data[0]
                    )
                )
            );

            rule.type.selected = [];
            rule.type.autoFocus = true;
            rule.field.selected = [];
            rule.field.options.splice(1);
            rule.field.values = [];
            rule.test.selected = [];
            rule.value.value = '';
            rule.foreground.selected = [];
            rule.background.selected = [];
            target.selectionRules.data.push(rule);

            return target;

        case DELETE_SELECTION_RULE:
            target = { ...state };
            if (target.selectionRules.data?.length === 1) {
                target.selectionRulesBackup = JSON.parse(JSON.stringify(target.selectionRules.data[0]));
            }
            target.selectionRules.data.splice(action.payload, 1);
            return target;

        case MOVE_UP_SELECTION_RULE:
            target = { ...state };
            item = target.selectionRules.data[action.payload];
            target.selectionRules.data.splice(action.payload, 1);
            target.selectionRules.data.splice(action.payload - 1, 0, item);

            return target;

        case MOVE_DOWN_SELECTION_RULE:
            target = { ...state };
            item = target.selectionRules.data[action.payload];
            target.selectionRules.data.splice(action.payload, 1);
            target.selectionRules.data.splice(action.payload + 1, 0, item);

            return target;

        case ADD_ACTION_RULE:
            target = { ...state };
            rule = Object.assign(
                {},
                JSON.parse(
                    JSON.stringify(target.actionRulesBackup ? target.actionRulesBackup : target.actionRules.data[0])
                )
            );

            rule.name.value = '';
            rule.name.autoFocus = true;
            rule.type.selected = [];
            rule.uri.value = '';
            rule.target.selected = [];
            rule.rightClick.selected = [];
            rule.separator.selected = [];
            rule.viewScreenButton.selected = [];
            rule.confirm.options[0].checked = "false";
            rule.roles.selected = [];
            target.actionRules.data.push(rule);

            return target;

        case DELETE_ACTION_RULE:
            target = { ...state };
            if (target.actionRules.data?.length === 1) {
                target.actionRulesBackup = JSON.parse(JSON.stringify(target.actionRules.data[0]));
            }
            target.actionRules.data.splice(action.payload, 1);
            return target;

        case MOVE_UP_ACTION_RULE:
            target = { ...state };
            item = target.actionRules.data[action.payload];
            target.actionRules.data.splice(action.payload, 1);
            target.actionRules.data.splice(action.payload - 1, 0, item);

            return target;

        case MOVE_DOWN_ACTION_RULE:
            target = { ...state };
            item = target.actionRules.data[action.payload];
            target.actionRules.data.splice(action.payload, 1);
            target.actionRules.data.splice(action.payload + 1, 0, item);

            return target;

        case SELECTION_TYPE_CHANGED:
            target = { ...state };
            target.selectionRules.data[action.payload.index].type.selected = [action.payload.value];

            return target;

        case SELECTION_FIELD_CHANGED:
            target = { ...state };
            if (target.selectionRules.data[action.payload.index]?.field) {
                if (action.payload.value) {
                    target.selectionRules.data[action.payload.index].field.selected = [action.payload.value];
                    target.selectionRules.data[action.payload.index].field.options = [action.payload.value];
                } else {
                    target.selectionRules.data[action.payload.index].field.selected = [];
                    target.selectionRules.data[action.payload.index].field.options = [];
                }
            }

            return target;

        case SELECTION_TEST_CHANGED:
            target = { ...state };
            target.selectionRules.data[action.payload.index].test.selected = [action.payload.value];

            return target;

        case SELECTION_VALUE_CHANGED:
            target = { ...state };
            target.selectionRules.data[action.payload.index][action.payload.field].value = action.payload.value;

            return target;

        case SELECTION_FOREGROUND_CHANGED:
            target = { ...state };
            target.selectionRules.data[action.payload.index].foreground.selected = [action.payload.value];

            return target;

        case SELECTION_BACKGROUND_CHANGED:
            target = { ...state };
            target.selectionRules.data[action.payload.index].background.selected = [action.payload.value];

            return target;

        case CLEAN_UP_DATA:
            target = { ...state };
            target.isFormSubmitted = true;
            return cleanRow(
                target,
                [target.selectionRules.model, target.actionRules.model],
                ['selectionRules', 'actionRules']
            );

        case SAVING_FOLDER:
            target = { ...state };
            target.success = null;
            target.savingFolder = true;
            return target;

        case SAVE_FOLDER_COMPLETE:
            target = { ...state };
            target.success = action.payload.success ? 'OK' : 'FAILED';
            target.folderId = action.payload.folderId;
            target.message = action.payload.message;
            target.savingFolder = false;
            return target;

        case SAVE_FOLDER_FAILED:
            target = { ...state };
            target.success = 'FAILED';
            target.message = action.payload.message;
            target.savingFolder = false;
            return target;

        case TRIGGER_FORM_SUBMITTED:
            target = { ...state };
            target.isFormSubmitted = true;
            return target;
        case GRID_SELECT_VALUE_CHANGED:
            target = { ...state };
            let object = target[action.payload.section].data[action.payload.index][action.payload.key];
            if (!object) {
                return target;
            }
            if (object.type === 'CHECKBOX') {
                object.options[0].checked = action.payload.value === true ? "true" : "false";
            } else {
                object.selected = Array.isArray(
                    action.payload.value
                )
                    ? action.payload.value
                    : [action.payload.value];
            }
            return target;

        case GRID_INPUT_VALUE_CHANGED:
            target = { ...state };
            target[action.payload.section].data[action.payload.index][action.payload.key].value = action.payload.value;
            return target;

        case WIDGET_FIELD_CHANGED:
            target = { ...state };
            if (target.widgetRules.data[action.payload.index]?.field) {
                if (action.payload.value) {
                    target.widgetRules.data[action.payload.index].field.selected = [action.payload.value];
                    target.widgetRules.data[action.payload.index].field.options = [action.payload.value];
                } else {
                    target.widgetRules.data[action.payload.index].field.selected = [];
                    target.widgetRules.data[action.payload.index].field.options = [];
                }
            }

            return target;

        case WIDGET_RULE_VALUE_CHANGED:
            target = { ...state };
            target.widgetRules.data[action.payload.index].value.value = action.payload.value;

            return target;

        case WIDGET_RULE_LABEL_CHANGED:
            target = { ...state };
            target.widgetRules.data[action.payload.index].label.value = action.payload.value;

            return target;

        case ADD_WIDGET_RULE:
            target = { ...state };
            rule = Object.assign(
                {},
                JSON.parse(
                    JSON.stringify(
                        target.widgetRulesBackup ? target.widgetRulesBackup : target.widgetRules.data[0]
                    )
                )
            );

            rule.field.selected = [];
            rule.field.values = [];
            rule.test.selected = [];
            rule.value.value = '';
            rule.action.selected = [];
            rule.actionOn.selected = [];
            rule.actionOn.values = [];
            rule.foreground.selected = [];
            rule.background.selected = [];
            rule.label.value = '';
            target.widgetRules.data.push(rule);

            return target;

        case CHANGE_SELECTED_WIDGET_ID:
            return { ...state, selectedWidgetId: action.payload };

        case SAVE_WIDGET_RULES_COMPLETE:
            target = { ...state, isWidgetRulesLoading: false };
            target.widgetsuccess = action.payload.status == "success" ? 'OK' : 'FAILED';
            target.folderId = action.payload.folderId;
            target.widgetmessage = action.payload.message;
            target.selectedWidgetId = action.payload.widgetId;
            return target;

        case SAVE_WIDGET_RULES_FAILED:
            target = { ...state };
            target.widgetsuccess = 'FAILED';
            target.widgetmessage = action.payload.message;
            return target;

        case DELETE_WDGET_RULE:
            target = { ...state };
            if (target.widgetRules.data?.length === 1) {
                target.widgetRulesBackup = JSON.parse(JSON.stringify(target.widgetRules.data[0]));
            }
            target.widgetRules.data.splice(action.payload, 1);
            return target;

        case WIDGET_NAME_CHANGED:
            target = { ...state };
            target.widgetRules.widget.name.value = action.payload.value;
            return target;

        case WIDGET_SHOWCOUNT_CHANGED:
            target = { ...state };
            target.widgetRules.widget.showCount.options[0].checked = !(
                target.widgetRules.widget.showCount.options[0].checked === 'true'
            )
                ? 'true'
                : 'false';
            return target;

        case WIDGET_TYPE_CHANGED:
            target = { ...state };
            target.widgetRules.widget.type.selected = [action.payload];

            let rule = Object.assign(
                {},
                JSON.parse(
                    JSON.stringify(
                        target.widgetRulesBackup ? target.widgetRulesBackup : target.widgetRules.data[0]
                    )
                )
            );
            if (target.widgetRules.widget.type.selected[0].value !== 'S') {
                target.widgetRules.data = [];
                rule.field.selected = [];
                rule.field.values = [];
                rule.test.selected = [];
                rule.value.value = '';
                rule.action.selected = [];
                rule.actionOn.selected = [];
                rule.actionOn.values = [];
                rule.foreground.selected = [];
                rule.background.selected = [];
                rule.label.value = '';
                target.widgetRules.data.push(rule);

                var newGroup = []
                let rule1 = Object.assign(
                    {},
                    JSON.parse(
                        JSON.stringify(
                            target["widgetRulesByGroup"][0][0]
                        )
                    )
                );

                rule1.field.selected = [];
                rule1.field.values = [];
                rule1.test.selected = [];
                rule1.value.value = '';
                rule1.action.selected = [];
                rule1.actionOn.selected = [];
                rule1.actionOn.values = [];
                rule1.label.value = '';
                rule1.standardNo.value = 0;
                rule1.expandedNo.value = 0;
                rule1.groupId.value = '';
                newGroup.push(rule1);
                target["widgetRulesByGroup"].pop();
                target["widgetRulesByGroup"].push(newGroup);

            } else {
                target.widgetRules.data = target.summaryrules
            }
            return {
                ...state,
            };

        case WIDGET_ACTION_ON_CHANGED:
            target = { ...state };
            if (target.widgetRules.data[action.payload.index]?.actionOn) {
                if (action.payload.value) {
                    target.widgetRules.data[action.payload.index].actionOn.selected = [action.payload.value];
                    target.widgetRules.data[action.payload.index].actionOn.options = [action.payload.value];
                } else {
                    target.widgetRules.data[action.payload.index].actionOn.selected = [];
                    target.widgetRules.data[action.payload.index].actionOn.options = [];
                }
            }
        case CLEAN_WIDGET_RULES_DATA:
            return {
                ...state,
                isWidgetRulesLoading: false,
                widgetRulesLoaded: true,
                widgetRules: [],
                widgetsuccess: ""
            };
            return target;

        case LOADING_WIDGET_RULES_DATA:
            return { ...state, isWidgetRulesLoading: true };

        case LOAD_FOLDER_WIDGET_LIST_SUCCESS:
            return {
                ...state,
                isWidgetListLoading: false,
                widgetListLoaded: true,
                widgetList: action.payload,
            };
        case BAR_WIDGET_GROUP_SELECT_VALUE_CHANGED:
            target = { ...state };
            let widgetGroup = target[action.payload.section][action.payload.index];
            for (let i = 0; i < widgetGroup.length; i++) {
                widgetGroup[i][action.payload.key].selected = Array.isArray(action.payload.value) ? action.payload.value : [action.payload.value];
            }
            return target;

        case BAR_WIDGET_NUMBER_INPUT_CHANGED:
            target = { ...state };
            let numberInput = target[action.payload.section][action.payload.index];
            for (let i = 0; i < numberInput.length; i++) {
                numberInput[i][action.payload.key].value = action.payload.value;
            }
            return target;

        case BAR_GRID_SELECT_VALUE_CHANGED:
            target = { ...state };
            let object1 = target[action.payload.section][action.payload.pIndex][action.payload.chIndex][action.payload.key]
            object1.selected = Array.isArray(action.payload.value) ? action.payload.value : [action.payload.value];
            return target;
        case BAR_WIDGET_RULE_LEBEL_CHANGED:
            target = { ...state };
            target["widgetRulesByGroup"][action.payload.pIndex][action.payload.chIndex].label.value = action.payload.value;
            return target;
        case ADD_BAR_WIDGET_RULE:
            target = { ...state };
            rule = Object.assign(
                {},
                JSON.parse(
                    JSON.stringify(
                        target.widgetRulesByGroupBackup ? target.widgetRulesByGroupBackup : target["widgetRulesByGroup"][action.payload][0]
                    )
                )
            );

            rule.test.selected = [];
            rule.value.value = '';
            rule.action.selected = [];
            rule.actionOn.selected = [];
            rule.actionOn.values = [];
            rule.label.value = '';
            target["widgetRulesByGroup"][action.payload].push(rule);

            return target;
        case BAR_WIDGET_RULE_DELETE:
            target = { ...state };
            if (target["widgetRulesByGroup"][action.payload.pIndex]?.length === 1) {
                target.widgetRulesByGroupBackup = JSON.parse(JSON.stringify(target["widgetRulesByGroup"][action.payload.pIndex][0]));
            }
            target["widgetRulesByGroup"][action.payload.pIndex].splice(action.payload.chIndex, 1);
            return target;
        case ADD_BAR_WIDGET_RULE_GROUP:
            target = { ...state };
            var newGroup = []
            rule = Object.assign(
                {},
                JSON.parse(
                    JSON.stringify(
                        target["widgetRulesByGroup"][0][0]
                    )
                )
            );

            rule.field.selected = [];
            rule.field.values = [];
            rule.test.selected = [];
            rule.value.value = '';
            rule.action.selected = [];
            rule.actionOn.selected = [];
            rule.actionOn.values = [];
            rule.label.value = '';
            rule.standardNo.value = 0;
            rule.expandedNo.value = 0;
            rule.groupId.value = '';
            newGroup.push(rule);
            target["widgetRulesByGroup"].push(newGroup);

            return target;
        case BAR_WIDGET_RULE_GROUP_DELETE:
            target = { ...state };
            if (target["widgetRulesByGroup"][action.payload.pIndex]?.length === 1) {
                target.widgetRulesByGroupBackup = JSON.parse(JSON.stringify(target["widgetRulesByGroup"][action.payload.pIndex][0]));
            }
            target["widgetRulesByGroup"].splice(action.payload.pIndex, 1);
            return target;
        case BAR_SHOW_REST_CHECK_BOX_CHANGED:
            target = { ...state };
            let checkbox = target[action.payload.section][action.payload.index];
            for (let i = 0; i < checkbox.length; i++) {
                checkbox[i][action.payload.key].options[0].checked = action.payload.value === true ? "true" : "false";
            }
            return target;
        case BAR_WIDGET_GROUP_BY_DATE_SELECT_VALUE_CHANGED:
            target = { ...state };
            let widgetGroupByDate = target[action.payload.section][action.payload.index];
            for (let i = 0; i < widgetGroupByDate.length; i++) {
                widgetGroupByDate[i][action.payload.key].selected = Array.isArray(action.payload.value) ? action.payload.value : [action.payload.value];
            }
            return target;
        case DELETING_FOLDER_WIDGET:
            target = { ...state };
            target.widgetDeleted = action.payload;
            return target;
        case DELETING_FOLDER_WIDGET_SUCCESS:
            target = { ...state };
            target.widgetDeleted = action.payload;
            return target;
        case WIDGET_COLOR_VALUE_CHANGED:
            target = { ...state };
            target.widgetRules.data[action.payload.index][action.payload.name].value = action.payload.value;

            return target;
        default:
            return state;
    }
};

const cleanRow = (target, models, names) => {
    names.forEach((name, id) => {
        if (target[name].data.length > 1) {
            let dt = target[name].data.filter((row, index) => {
                var ar = Object.keys(row);
                let result = true;
                ar.forEach((col) => {
                    let ob = row[col];
                    let nm = models[id] + 's[' + index + '].' + ob.name;
                    let val = document.getElementsByName(nm)[0]?.value;
                    if (!val || val?.trim().length === 0) {
                        result = false;
                        return false;
                    }
                    return result;
                });
                return result;
            });

            target[name].data = dt;
        }
    });

    return target;
};

export default Folder;
