import {
    TOOLBAR_OPENED,
    TOOLBAR_CLOSED,
    TOOLBAR_VALUE_CHANGED,
    SAVE_TOOLBAR,
    DELETE_TOOLBAR,
    RESET_TOOLBAR_FORM,
    SUCCESS_DISMISSED
} from './constants';

export const loadToolbar = (folderId, folderType)  => ({
    type: TOOLBAR_OPENED,
    payload: { folderId: folderId, folderType: folderType }
})

export const toolbarClosed = () => ({
    type: TOOLBAR_CLOSED
})

export const successDismissed = () => ({
    type: SUCCESS_DISMISSED
})

export const toolbarValueChanged = val => ({
    type: TOOLBAR_VALUE_CHANGED,
    payload: val
})

export const saveToolbar = () => ({
    type: SAVE_TOOLBAR
})

export const deleteToolbar = val => ({
    type: DELETE_TOOLBAR,
    payload: val
})

export const resetToolbarForm = val => ({
    type: RESET_TOOLBAR_FORM,
    payload: val
})