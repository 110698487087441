import { all, call, fork, put, takeLatest, delay } from 'redux-saga/effects';
import { fetchJSON } from '../../helpers/api';
import { axiosCSRF } from '../../helpers/api';
import { postData } from '../../helpers/api';

import {
    API_PREFIX,
    SAVE_SHORTCUTS,
    SAVE_SHORTCUTS_COMPLETE,
    SAVE_SHORTCUTS_FAILED

} from './constants';

function* saveShortcut(action) {
    if (action) {
        try {

            const response = yield call(postData, `${API_PREFIX}` + 'save/shortcuts/' + action.payload.table + '?mafversion=2', {
                method: 'POST',
                body: JSON.stringify(action.payload.shortcuts),
            });

            yield put({ type: SAVE_SHORTCUTS_COMPLETE, payload: response });

        } catch (error) {
            yield put({ type: SAVE_SHORTCUTS_FAILED, payload: {} });
        }
    }
}

export function* watchSaveShortcut() {
    yield takeLatest(SAVE_SHORTCUTS, saveShortcut);
}


function* shortcutSetupModalSaga() {
    yield all([fork(watchSaveShortcut)]);
}

export default shortcutSetupModalSaga;
