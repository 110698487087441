import { all, call, fork, put, takeLatest, delay } from 'redux-saga/effects';
import { fetchJSON } from '../../helpers/api';
import { axiosCSRF } from '../../helpers/api';
import { postData } from '../../helpers/api';

import {
    SET_VIEW,
    GET_VIEW,
    SAVE_COMPLETE,
    SAVE_FAILED

} from './constants';

function* saveViewType(action) {
    if (action) {
        try {

            var url = '/netcourier-data/system/user/set/hublistview/' + action.payload + '?mafversion=2';

            const response = yield call(postData, url, {
                method: 'POST',
            });

            // yield put({ type: SAVE_COMPLETE, payload: response });

        } catch (error) {
            // yield put({ type: SAVE_FAILED, payload: {} });
        }
    }
}

export function* watchSaveView() {
    //   yield takeLatest(SET_VIEW, saveViewType);
}


function* CompactViewSaga() {
    //  yield all([fork(watchSaveView)]);
}

export default CompactViewSaga;
