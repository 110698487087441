// @flow

import { combineReducers } from 'redux';
import Layout from './layout/reducers';
import Auth from './auth/reducers';
import AppMenu from './appMenu/reducers';
import Components from './component/reducers';
import StandardLayout from './standardLayout/reducers';
import Folder from './folder/reducers';
import Toolbar from './toolbar/reducers';
import RightBar from './rightbar/reducers';
import ShortcutModal from './shortcutmodal/reducers';
import CompactView from './compactView/reducers';

export default combineReducers({
    Auth,
    AppMenu,
    Layout,
    Components,
    StandardLayout,
    Folder,
    Toolbar,
    RightBar,
    ShortcutModal,
    CompactView
});
