import {
    TOOLBAR_OPENED,
    LOADING_TOOLBAR,
    LOADED_TOOLBAR,
    TOOLBAR_CLOSED,
    SUCCESS_DISMISSED,
    TOOLBAR_VALUE_CHANGED,
    RESET_TOOLBAR_FORM,
    TEXT_INPUT,
    SINGLE_SELECT,
    SINGLE_SEARCH,
    MULTI_SELECT,
    MULTI_SEARCH,
    SAVE_FOLDER_COMPLETE,
    SAVE_FOLDER_FAILED,
    DELETE_TOOLBAR_COMPLETE,
    DELETE_TOOLBAR_FAILED
} from './constants'
import { processData, setUpdatedValue } from '../../helpers/utils'

const Toolbar = (state = {
    isToolbarRulesLoading: false,
    toolbarRulesLoaded: false
}, action) => {
    var target = null

    switch (action.type) {
        case TOOLBAR_OPENED:
            return {...state,
                folderId: action.payload.folderId,
                toolbarRulesLoaded: false,
                success: false
            }

        case LOADING_TOOLBAR:
            return {...state,
                isToolbarRulesLoading: true
            }

        case LOADED_TOOLBAR:
            processData(action.payload.data)
            target = {...state,
                isToolbarRulesLoading: false,
                toolbarRulesLoaded: true,
                toolbarRules: action.payload
            }
            return target

        case TOOLBAR_CLOSED:
            return {
                isToolbarRulesLoading: false,
                toolbarRulesLoaded: false
            }

        case SUCCESS_DISMISSED:
            return {...state, success: ''}

        case TOOLBAR_VALUE_CHANGED:
            target = setUpdatedValue(state, 'toolbarRules', action.payload)
            return target

        case RESET_TOOLBAR_FORM:
            target = resetToolbarForm(state, action.payload)
            return target

        case SAVE_FOLDER_COMPLETE:
        case DELETE_TOOLBAR_COMPLETE:
            target = {...state}
            target.success = action.payload.success ? 'OK' : 'FAILED'
            target.folderId = action.payload.folderId ? action.payload.folderId : target.folderId;
            target.message = action.payload.message
            return target

        case SAVE_FOLDER_FAILED:
        case DELETE_TOOLBAR_FAILED:
            target = {...state}
            target.success = 'FAILED'
            target.message = action.payload.message
            return target

        default:
            return state
    }
}

const resetToolbarForm = (state, shouldSetOldValues) => {
    let target = {...state}
    Object.entries(target.toolbarRules.data).forEach(([key, input]) => {
        input.modified = false
        switch (input.type) {
            case TEXT_INPUT:
                if (shouldSetOldValues) {
                    input.value = input.oldValue
                    input.oldValue = null
                } else {
                    input.modified = false
                    input.oldValue = input.value
                    if (input.name !== 'folderType') {
                        input.value = null
                    }
                }
                break
            case SINGLE_SEARCH:
            case SINGLE_SELECT:
            case MULTI_SEARCH:
            case MULTI_SELECT:
                if (shouldSetOldValues) {
                    input.selected = input?.oldSelected?.slice()
                    input.oldSelected = []
                } else {
                    input.oldSelected = input?.selected?.slice()
                    input.selected = []
                }
                break
            default:
                break
        }
    })
    return target
}

export default Toolbar