// @flow
import jwtDecode from 'jwt-decode';
import { Cookies } from 'react-cookie';
import axios from 'axios';

/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
    const user = getLoggedInUser();
    if (!user) {
        return false;
    }
    const decoded = jwtDecode(user.token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
        console.warn('access token expired');
        return false;
    } else {
        return true;
    }
};

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
    const cookies = new Cookies();
    const user = cookies.get('user');
    return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
};

const isUserAuthenticatedByMetafourAuth = () => {
    const userLogedIn = localStorage.getItem('userLogedIn');
    if (userLogedIn) {
        return true;
    } else {
        return false;
    }
};

const doLoginUserBYMetafourAuth = async () => {
    let url = '/metafour-auth/Ping?v=' + Math.random();
    await axios.get(url).then((res) => {
        if (res.data.trim() === 'OK') {
            localStorage.setItem('userLogedIn', true);
            sleep(1000);
        } else {
            const cookies = new Cookies();
            cookies.remove('HUBLOGIN', { path: '/netcourier-data' });
            localStorage.removeItem('userLogedIn');
            localStorage.removeItem('currentActivePage');
            localStorage.removeItem('defaultPage');
            localStorage.removeItem('defaultPageFolderId');
            localStorage.removeItem('_csrf');
            window.location.href = '/netcourier-data/relogin';
        }
    });
};

function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

const logOutAndRedirect = async () => {
    const cookies = new Cookies();
    cookies.remove('HUBLOGIN', { path: '/netcourier-data' });
    localStorage.removeItem('currentActivePage');
    localStorage.removeItem('defaultPage');
    localStorage.removeItem('defaultPageFolderId');
    try {
        localStorage.removeItem('userLogedIn');
        localStorage.removeItem('_csrf');
        window.location.href = '/netcourier-data/relogin';

    } catch (error) {
        window.location.href = '/netcourier-data/relogin';
    }
};
export {
    isUserAuthenticated,
    getLoggedInUser,
    isUserAuthenticatedByMetafourAuth,
    doLoginUserBYMetafourAuth,
    logOutAndRedirect,
};
