import { all, call, fork, put, takeLatest, delay } from 'redux-saga/effects';
import { fetchJSON } from '../../helpers/api';
import {
    API_PREFIX,
    TOOLBAR_OPENED,
    LOADING_TOOLBAR,
    LOADED_TOOLBAR,
    SAVE_TOOLBAR,
    SAVING_FOLDER,
    SAVE_FOLDER_COMPLETE,
    SAVE_FOLDER_FAILED,
    DELETE_TOOLBAR,
    DELETING_TOOLBAR,
    DELETE_TOOLBAR_COMPLETE,
    DELETE_TOOLBAR_FAILED,
} from './constants';

function* initToolbarRules(action) {
    try {
        yield put({ type: LOADING_TOOLBAR });
        var response = [];
        if (action.payload.folderType) {
            response = yield call(fetchJSON, `${API_PREFIX}toolbarrules/${action.payload.folderId}?folderType=${action.payload.folderType}&mafversion=2`);
        } else {
            response = yield call(fetchJSON, `${API_PREFIX}toolbarrules/${action.payload.folderId}?mafversion=2`);
        }
        if (response.redirected) {
            if((response.url.match(/\?/g) || []).length > 1) {
                if  (action.payload.folderType){
                    window.location.href = response.url + `&toolbar=${action.payload.folderId}&folderType=${action.payload.folderType}`;
                } else {
                    window.location.href = response.url + `&toolbar=${action.payload.folderId}`;
                }
            } else {
                if  (action.payload.folderType){
                    window.location.href = response.url + `?toolbar=${action.payload.folderId}&folderType=${action.payload.folderType}`;
                } else {
                    window.location.href = response.url + `?toolbar=${action.payload.folderId}`;
                }
            }
            
        } else {
            yield put({ type: LOADED_TOOLBAR, payload: response });
        }
    } catch (error) {
        console.error(error);
    }
}

export function* watchToolbarRulesInit() {
    yield takeLatest(TOOLBAR_OPENED, initToolbarRules);
}

function* saveToolbar() {
    try {
        yield put({ type: SAVING_FOLDER });
        const response = yield call(fetchJSON, `${API_PREFIX}folder?mafversion=2`, {
            method: 'POST',
            body: new FormData(document.getElementById('toolbar-maintenance')),
        });
        yield put({ type: SAVE_FOLDER_COMPLETE, payload: response });
    } catch (error) {
        yield put({ type: SAVE_FOLDER_FAILED, payload: {} });
        console.error(error);
    }
}

export function* watchSaveFolderInit() {
    yield takeLatest(SAVE_TOOLBAR, saveToolbar);
}

function* deleteToolbar(action) {
    try {
        yield put({ type: DELETING_TOOLBAR });
        const response = yield call(fetchJSON, `${API_PREFIX}folder/delete/${action.payload}?mafversion=2`, {
            method: 'POST',
        });
        yield put({ type: DELETE_TOOLBAR_COMPLETE, payload: response });
    } catch (error) {
        yield put({ type: DELETE_TOOLBAR_FAILED, payload: {} });
        console.error(error);
    }
}

export function* watchDeleteToolbarInit() {
    yield takeLatest(DELETE_TOOLBAR, deleteToolbar);
}

function* toolbarSaga() {
    yield all([fork(watchToolbarRulesInit), fork(watchSaveFolderInit), fork(watchDeleteToolbarInit)]);
}

export default toolbarSaga;
