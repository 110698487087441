// @flow
import { all, call, put, fork, takeLatest } from 'redux-saga/effects';

import { INIT_MENU, CHANGE_ACTIVE_MENU_FROM_LOCATION } from './constants';
import { flattenRoutes } from '../../routes';
import assignIds from './utils';
import { initMenuSuccess, changeActiveMenuFromLocationSuccess, initFlattenMenuSuccess } from './actions';
import { fetchJSON } from '../../helpers/api';
import ls from 'local-storage';
import { logOutAndRedirect } from '../../helpers/authUtils';
import { FullScreenModuleRegexs } from '../../routes/Routes';

/**
 * Activate menu items from location
 * @param {*} menuItems
 */
const getActivatedMenuItemIds = (menuItems) => {
    var matchingItems = [];
    for (var menuItem of menuItems) {
        if (window.location.pathname.indexOf(process.env.PUBLIC_URL + menuItem.path) === 0) {
            matchingItems.push(menuItem.id);
        }

        if (typeof menuItem.children !== 'undefined') {
            matchingItems = [...matchingItems, ...getActivatedMenuItemIds(menuItem.children)];
        }
    }
    return matchingItems;
};

/**
 * Initilizes the menu
 */
function* initMenuAndItems() {
    try {
        let csrf = yield call(
            fetchJSON,
            '/netcourier-data/csrf'
        );

        let checkConsent = false;
        checkConsent = yield call(
            fetchJSON,
            '/netcourier-data/system/user/consent/check?mafversion=2&random=' + Math.random()
        );

        let hasCSRF = csrf && csrf.token && csrf.headerName === 'X-CSRF-TOKEN';
        if (hasCSRF) {
            localStorage.setItem('_csrf', csrf.token);
        } else {
            console.error('No CSRF token found');
        }
        if (checkConsent && hasCSRF) {
            const response = yield call(
                fetchJSON,
                '/netcourier-data/workspace/data/foldertree?mafversion=2&random=' + Math.random()
            );
            const menuItems = assignIds(response);
            yield put(initMenuSuccess(menuItems));
            const flattenRouters = flattenRoutes(response);
            yield put(initFlattenMenuSuccess(flattenRouters));
            ls.set('menuItems', menuItems);
            var foundDefault = false;

            flattenRouters.filter((item) => item.defaultPage).map((item, i) => {
                if (ls.get("defaultPage") == null && ls.get("defaultPageFolderId") == null) {
                    ls.set('defaultPage', item.path);
                    ls.set('defaultPageFolderId', item.folderId);
                } else if (ls.get("defaultPage") != null && ls.get("defaultPage") != item.path && ls.get("defaultPageFolderId") != item.folderId) {
                    ls.set('defaultPage', item.path);
                    ls.set('defaultPageFolderId', item.folderId);
                }
                foundDefault = true;
            });
            if (!foundDefault) {
                ls.remove('defaultPage');
                ls.remove('defaultPageFolderId');
            }

            var foundDashboard = false;
            flattenRouters.filter((item) => item.type === "T" && item.listurl === "/netcourier-data/dashboard").map((item, i) => {
                foundDashboard = true;
            });

            if (!foundDashboard && !foundDefault) {
                flattenRouters.filter((item) => item.type === "F").map((item, i) => {
                    if (ls.get("defaultPage") == null && ls.get("defaultPageFolderId") == null) {
                        ls.set('defaultPage', item.path);
                        ls.set('defaultPageFolderId', item.folderId);
                    }
                });
            }

            const urlParams = new URLSearchParams(window.location.search);
            const onlyContent = FullScreenModuleRegexs.find((rg) => rg.test(document.location.pathname)) != undefined;
            if (!onlyContent && ls.get("defaultPage") != null && ls.get("defaultPageFolderId") != null
                && window.location.pathname != "/app" + ls.get("defaultPage") && ls.get("currentActivePage") == null && urlParams.get('toolbar') !== "true") {
                window.location.href = "/app" + ls.get("defaultPage");
            }

        } else {
            window.location.href = '/netcourier-data/user/termsofuse?originpath=/app';
        }
    } catch (error) {
        if ((error instanceof SyntaxError) || (error.response && (error.response.status == 404 || error.response.status == 500))) logOutAndRedirect();
    }
}

/**
 * change the active menu item based on location
 */
function* changeActiveMenuFromLocation() {
    try {
        if (ls.get('menuItems')) {
            const menuItems = assignIds(ls.get('menuItems'));
            const activatedMenuItemIds = yield call(getActivatedMenuItemIds, menuItems);
            yield put(changeActiveMenuFromLocationSuccess(activatedMenuItemIds));
        } else {
            yield put(changeActiveMenuFromLocationSuccess([]));
        }
    } catch (error) { }
}

/**
 * Watchers
 */
export function* watchMenuInit(): any {
    yield takeLatest(INIT_MENU, initMenuAndItems);
}

export function* watchMenuChange(): any {
    yield takeLatest(CHANGE_ACTIVE_MENU_FROM_LOCATION, changeActiveMenuFromLocation);
}

function* appMenuSaga(): any {
    yield all([fork(watchMenuInit), fork(watchMenuChange)]);
}

export default appMenuSaga;
