import {
    SET_VIEW,
    GET_VIEW
} from './constants';

export const setCompactView = (value: String) => ({
    type: SET_VIEW,
    payload: value
})

export const getCompactView = () => ({
    type: GET_VIEW
})
