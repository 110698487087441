// @flow
import 'semantic-ui-css/semantic.min.css';
import React, { Component } from 'react';
import Routes from './routes/Routes';
import { Cookies } from 'react-cookie';
import axios from 'axios';
import { loadFontFaces } from './helpers/utils';
import { connect } from 'react-redux';

import { fontLoaded } from './redux/actions';

// Themes
import './assets/scss/Saas.scss';

// For Dark import Saas-Dark.scss
// import './assets/scss/Saas-Dark.scss';

// For Modern import Modern.scss
// import './assets/scss/Modern.scss';
// For modern dakr import Modern-Dark.scss
// import './assets/scss/Modern-Dark.scss';

// For Creative demo import Modern.scss
// import './assets/scss/Creative.scss';
// For Creative dark demo import Modern.scss
// import './assets/scss/Creative-Dark.scss';

type AppProps = {};

/**
 * Main app component
 */
class App extends Component<AppProps> {
    constructor(props) {
        super(props);
        this.state = {
            isLoggedin: false,
            redirectClass: "alert-info",
            redirectText: "Redirecting ..",
            gotError: false
        };
    }
    shouldComponentUpdate = (nextProps, nextState) => {
        return nextState.isLoggedin || nextState.gotError;
    };

    async multiFactorAuth() {
        await axios.get('/netcourier-data/mfarequired').then((res) => {
            if (res.data === true) window.location.href = '/netcourier-data/mfa?continue=/app/';
        }).catch(function (error) {
            console.error(error);
        });
    };

    async componentDidMount() {
        const fontLoaded = await this.updateFontStatePeriodically();

        // Code to execute after fonts are loaded
        if (fontLoaded) {
            // Font loading was successful
            console.log('All fonts loaded.');
            // Add your code here
        } else {
            // Font loading failed
            console.log('Fonts failed to load.');
            // Handle the failure if needed
        }
        let url = '/metafour-auth/Ping?v=' + Math.random();
        await axios.get(url).then(async (res) => {
            if (res.data.trim() === 'OK') {
                await this.multiFactorAuth();
                localStorage.setItem('userLogedIn', true);
                this.setState({ isLoggedin: true, gotError: false });
            } else {
                localStorage.removeItem('userLogedIn');
                const cookies = new Cookies();
                cookies.remove('HUBLOGIN', { path: '/netcourier-data' });
                localStorage.removeItem('currentActivePage');
                localStorage.removeItem('defaultPage');
                localStorage.removeItem('defaultPageFolderId');
                localStorage.removeItem('_csrf');
                window.location.href = '/metafour-auth/logout.jsp?appId=ncback&accessCode=&continue=/app/';
            }
        }).catch(function (error) {
            this.setState({ redirectText: "Service Unavailable. Please contact your system administrator for more details.", redirectClass: "alert-danger", gotError: true });
        }.bind(this));
    }


    updateFontStatePeriodically = () => {
        return new Promise((resolve) => {
            let intervalId = setInterval(async () => {
                const fontLoaded = await loadFontFaces();

                if (fontLoaded) {
                    this.props.fontLoaded(true);
                    clearInterval(intervalId);
                    resolve(true);
                } else {
                    this.props.fontLoaded(false);
                }
            }, 500);
        });
    };

    render() {
        if (!this.state.isLoggedin) {
            return (
                <>
                    <h3 className={`redirecting text-center alert ${this.state.redirectClass} `}>{this.state.redirectText}</h3>
                </>
            );
        } else {
            return <Routes></Routes>;
        }
    }
}

const mapStateToProps = ({ RightBar }) => ({
    expandState: RightBar.expandState,
});

export default connect(mapStateToProps, { fontLoaded })(App);

