import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { fetchJSON } from '../../helpers/api';
import {
    API_PREFIX,
    LOAD_FOLDER_RULES_DATA,
    LOADING_FOLDER_RULES_DATA,
    LOAD_FOLDER_RULES_DATA_SUCCESS,
    LOAD_COLUMN_RULES_DATA,
    LOADING_COLUMN_RULES_DATA,
    LOAD_COLUMN_RULES_DATA_SUCCESS,
    LOAD_SELECTION_RULES_DATA,
    LOADING_SELECTION_RULES_DATA,
    LOAD_SELECTION_RULES_DATA_SUCCESS,
    LOAD_ACTION_RULES_DATA,
    LOADING_ACTION_RULES_DATA,
    LOAD_ACTION_RULES_DATA_SUCCESS,
    SAVE_FOLDER,
    SAVING_FOLDER,
    SAVE_FOLDER_COMPLETE,
    SAVE_FOLDER_FAILED,
    LOADING_WIDGET_RULES_DATA,
    LOAD_WIDGET_RULES_DATA,
    LOAD_WIDGET_RULES_DATA_SUCCESS,
    LOADING_FOLDER_WIDGET_LIST,
    LOAD_FOLDER_WIDGET_LIST,
    LOAD_FOLDER_WIDGET_LIST_SUCCESS,
    SAVE_WIDGET_RULE,
    SAVE_WIDGET_RULES_COMPLETE,
    SAVE_WIDGET_RULES_FAILED,
    DELETE_FOLDER_WIDGET,
    DELETING_FOLDER_WIDGET,
    DELETING_FOLDER_WIDGET_SUCCESS
} from './constants';

import * as DOMPurify from 'dompurify';

const getMafVersionParameter = (payload) => (payload.includes('?') ? '&' : '?') + 'mafversion=2';

function* initFolderRules(action) {
    try {
        yield put({ type: LOADING_FOLDER_RULES_DATA });
        let mafversion = getMafVersionParameter(action.payload);
        const response = yield call(fetchJSON, `${API_PREFIX}folderrules/${action.payload}${mafversion}`);
        yield put({ type: LOAD_FOLDER_RULES_DATA_SUCCESS, payload: response });
    } catch (error) {
        console.error(error);
    }
}

function* initColumnRules(action) {
    try {
        yield put({ type: LOADING_COLUMN_RULES_DATA });
        let mafversion = getMafVersionParameter(action.payload);
        const response = yield call(fetchJSON, `${API_PREFIX}columnrules/${action.payload}${mafversion}`);
        yield put({ type: LOAD_COLUMN_RULES_DATA_SUCCESS, payload: response });
    } catch (error) {
        console.error(error);
    }
}

function* initSelectionRules(action) {
    try {
        yield put({ type: LOADING_SELECTION_RULES_DATA });
        let mafversion = getMafVersionParameter(action.payload);
        const response = yield call(fetchJSON, `${API_PREFIX}selectionrules/${action.payload}${mafversion}`);
        yield put({ type: LOAD_SELECTION_RULES_DATA_SUCCESS, payload: response });
    } catch (error) {
        console.error(error);
    }
}

function* initActionRules(action) {
    try {
        yield put({ type: LOADING_ACTION_RULES_DATA });
        let mafversion = getMafVersionParameter(action.payload);
        const response = yield call(fetchJSON, `${API_PREFIX}actionrules/${action.payload}${mafversion}`);
        yield put({ type: LOAD_ACTION_RULES_DATA_SUCCESS, payload: response });
    } catch (error) {
        console.error(error);
    }
}


function* saveFolder(action) {
    try {
        yield put({ type: SAVING_FOLDER });
        let mafversion = getMafVersionParameter('');
        let formData = new FormData(document.getElementById('folder-maintenance'));

        let emptyInputs = [];
        let actionRules = [];
        for (let ob of formData.entries()) {
            if (ob[1] == null || ob[1]?.trim().length === 0) {
                emptyInputs.push(ob[0]);
                actionRules.push(ob[0]);
            }
        }
        if (actionRules.includes('Actions[0].name') &&
            actionRules.includes('Actions[0].type') &&
            actionRules.includes('Actions[0].fileUrl') &&
            actionRules.includes('Actions[0].target') &&
            actionRules.includes('Actions[0].rightClick') &&
            actionRules.includes('Actions[0].separator') &&
            actionRules.includes('Actions[0].viewScreen')) {
            emptyInputs.push('Actions[0].roles');
        }
        emptyInputs.forEach(name => formData.delete(name));

        for (let ob of formData.entries()) {
            let response = {
                status: "error",
                success: false
            };
            let initialVal = ob[1];

            let cleanValue = DOMPurify.sanitize(ob[1]);
            if (initialVal === "<" || initialVal === "<=") cleanValue = initialVal;
            if (initialVal !== cleanValue) {
                response.message = "Prohibited data in " + ob[0];
                yield put({ type: SAVE_FOLDER_COMPLETE, payload: response });
                return false;
            }

        }

        const response = yield call(fetchJSON, `${API_PREFIX}folder${mafversion}&copyFolderId=${action.payload.copyFolderId}`, {
            method: 'POST',
            body: formData,
        });

        yield put({ type: SAVE_FOLDER_COMPLETE, payload: response });
    } catch (error) {
        yield put({ type: SAVE_FOLDER_FAILED, payload: {} });
        console.error(error);
    }
}

function* initWidgetRules(action) {
    if (action) {
        try {
            yield put({ type: LOADING_WIDGET_RULES_DATA });
            const response = yield call(fetchJSON, `${API_PREFIX}widget/definitions/${action.payload.folderId}/${action.payload.widgetId}?'mafversion=2'`);
            yield put({ type: LOAD_WIDGET_RULES_DATA_SUCCESS, payload: response });
        } catch (error) {
            console.error(error);
        }
    }
}

function* initWidgetList(action) {
    try {
        yield put({ type: LOADING_FOLDER_WIDGET_LIST });
        let mafversion = getMafVersionParameter('');
        const response = yield call(fetchJSON, `${API_PREFIX}widget/list/${action.payload}${mafversion}`);
        yield put({ type: LOAD_FOLDER_WIDGET_LIST_SUCCESS, payload: response });
    } catch (error) {
        console.error(error);
    }
}

function* saveWidgetRule(action) {
    try {
        yield put({ type: LOADING_WIDGET_RULES_DATA });
        let mafversion = getMafVersionParameter('');
        let formData = new FormData(document.getElementById('widget-maintenance'));
        let folderId = document.getElementById("folderId").value;

        let emptyInputs = [];
        let actionRules = [];
        for (let ob of formData.entries()) {
            if (ob[1] == null || ob[1]?.trim().length === 0) {
                emptyInputs.push(ob[0]);
            }
        }
        emptyInputs.forEach(name => formData.delete(name));
        const response = yield call(fetchJSON, `${API_PREFIX}save/widget${mafversion}`, {
            method: 'POST',
            body: formData,
        });

        yield put({ type: SAVE_WIDGET_RULES_COMPLETE, payload: response });
        yield put({ type: LOAD_FOLDER_WIDGET_LIST, payload: folderId });

    } catch (error) {
        yield put({ type: SAVE_WIDGET_RULES_FAILED, payload: {} });
        console.error(error);
    }
}

function* deleteFolderWidget(action) {
    if (action) {
        yield put({ type: DELETING_FOLDER_WIDGET, payload: false });
        try {
            const response = yield call(fetchJSON, `${API_PREFIX}delete/widget/${action.payload.widgetId}?'mafversion=2'`, {
                method: 'POST',
            });
            yield put({ type: SAVE_FOLDER_COMPLETE, payload: response });
            // yield put({ type: LOAD_FOLDER_WIDGET_LIST,  payload: action.payload.folderId  });
            yield put({ type: DELETING_FOLDER_WIDGET_SUCCESS, payload: true });
        } catch (error) {
            console.error(error);
        }
    }
}

export function* watchSaveFolderInit() {
    yield takeLatest(SAVE_FOLDER, saveFolder);
}

export function* watchColumnRulesInit() {
    yield takeLatest(LOAD_COLUMN_RULES_DATA, initColumnRules);
}

export function* watchFolderRulesInit() {
    yield takeLatest(LOAD_FOLDER_RULES_DATA, initFolderRules);
}

export function* watchSelectionRulesInit() {
    yield takeLatest(LOAD_SELECTION_RULES_DATA, initSelectionRules);
}

export function* watchActionRulesInit() {
    yield takeLatest(LOAD_ACTION_RULES_DATA, initActionRules);
}

export function* watchWidgetRulesInit() {
    yield takeLatest(LOAD_WIDGET_RULES_DATA, initWidgetRules);
}

export function* watchSaveWidgetRulesInit() {
    yield takeLatest(SAVE_WIDGET_RULE, saveWidgetRule);
}

export function* watchFolderWidgetListInit() {
    yield takeLatest(LOAD_FOLDER_WIDGET_LIST, initWidgetList);
}

export function* watchDeleteFolderWidget() {
    yield takeLatest(DELETE_FOLDER_WIDGET, deleteFolderWidget);
}



function* folderSaga() {
    yield all([
        fork(watchFolderRulesInit),
        fork(watchColumnRulesInit),
        fork(watchSelectionRulesInit),
        fork(watchActionRulesInit),
        fork(watchSaveFolderInit),
        fork(watchWidgetRulesInit),
        fork(watchSaveWidgetRulesInit),
        fork(watchFolderWidgetListInit),
        fork(watchDeleteFolderWidget),
    ]);
}

export default folderSaga;
