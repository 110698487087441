import axios from 'axios';
import { Cookies } from 'react-cookie';

const cookies = new Cookies()

axios.interceptors.request.use(
    config => {
        config.headers['X-CSRF-TOKEN'] = localStorage.getItem('_csrf');
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

/**
 * Fetch data from given url
 * @param {*} url
 * @param {*} options
 */
const fetchJSON = async (url, options = {}) => {
    try {
        const response = await fetch(url, {
            ...options,
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRF-TOKEN': localStorage.getItem('_csrf'),
            },
        });
        if (response.redirected) {
            window.location.href = response.url;
            return [];
        } else if (!response.status === 200) {
            throw response.json();
        }
        const json = await response.json();
        return json;
    } catch (error) {
        throw error;
    }
};


const postData = async (url, options = {}) => {
    try {
        const response = await fetch(url, {
            ...options,
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Content-type': 'application/json',
                'X-CSRF-TOKEN': localStorage.getItem('_csrf'),
            },
        });
        if (response.redirected) {
            const json = await response;
            return json;
        } else if (!response.status === 200) {
            throw response.json();
        }
        const json = await response.json();
        return json;
    } catch (error) {
        throw error;
    }
};

export { fetchJSON, axios as axiosCSRF, postData };
