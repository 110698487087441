import React, { Component } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import { PrivateRoute, OpenRoute, authroutes } from './index';
import { isUserAuthenticatedByMetafourAuth } from '../helpers/authUtils';

// All layouts/containers
import AuthLayout from '../layouts/Auth';
import VerticalLayout from '../layouts/Vertical';
import { initMenu, changeActiveMenuFromLocation } from '../redux/actions';
import { expandRightSidebar } from '../redux/actions';
import { Cookies } from 'react-cookie';
import Loader from '../components/Loader';

export const FullScreenModuleRegexs = [
    /\/app\/foldermaintenance$/,
    /\/app\/foldermaintenance\/folder\/\d+$/,
    /\/app\/rolemaintenance\/\d+$/,
    /\/app\/rolemaintenance\/new/,
    /\/app\/profitandloss$/,
    /\/app\/notepad$/,
    /\/app\/watchedjobs$/,
    /\/app\/samedaywidget$/,
    /\/app\/operationalHoursWidget/,
    /\/app\/asynctasks$/,
    /\/app\/clientsonstop$/,
    /\/app\/foldersummary$/,
    /\/app\/folderpiechart$/,
    /\/app\/folderbarchart$/,
    /\/app\/foldertable$/,
    /\/app\/foldertable\/\d+$/,
];

const cookies = new Cookies();
class Routes extends Component {
    async componentDidMount() {
        if (!this.props.menu.menuItems) {
            this.props.initMenu();
            cookies.set('HUBLOGIN', true, { path: '/netcourier-data;SameSite=Lax' });
        }
    }

    // returns the layout
    getLayout = () => {
        if (!isUserAuthenticatedByMetafourAuth()) return AuthLayout;
        let layoutCls = VerticalLayout;
        return layoutCls;
    };

    componentWillUnmount = () => {
        cookies.remove('HUBLOGIN', { path: '/netcourier-data' });
        localStorage.removeItem('currentActivePage');
        localStorage.removeItem('defaultPage');
        localStorage.removeItem('defaultPageFolderId');
    }

    shouldComponentUpdate(nextProps, nextState) {
        let oldFlatMenu = { ...this.props.menu };
        let newFlatMenu = { ...nextProps.menu };
        return (
            JSON.stringify(oldFlatMenu.flattenRouters) !== JSON.stringify(newFlatMenu.flattenRouters)
        );
    }

    render() {
        const Layout = this.getLayout();
        const onlyContent = FullScreenModuleRegexs.find((rg) => rg.test(document.location.pathname)) != undefined;
        if (onlyContent) {
            this.props.expandRightSidebar('fullscreen');
        }
        const props = { ...this.props, onlyContent: onlyContent };
        // rendering the router with layout
        return (
            <BrowserRouter basename="/app">
                {!this.props.menu.menuItems ? (<Loader></Loader>) : (
                    <Layout props={props}>
                        <Switch>
                            {this.props.menu.flattenRouters &&
                                this.props.menu.flattenRouters.map((route, index) => {
                                    let layout = '';
                                    switch (route.component) {
                                        case '0':
                                            layout = 'StandardLayout';
                                            break;
                                        case '6':
                                            layout = 'ListView';
                                            break;
                                        case '7':
                                            layout = 'DetailsView';
                                            break;
                                        case 'RootComponent':
                                            layout = 'RootComponent';
                                            break;
                                        default:
                                            layout = 'DetailsView';
                                            break;
                                    }

                                    return (
                                        <PrivateRoute
                                            key={index}
                                            path={`${route.path}`}
                                            roles={route.roles}
                                            exact={true}
                                            component={layout}
                                            defaultPage={route.defaultPage}></PrivateRoute>
                                    );
                                })}

                            <PrivateRoute path="/profitandloss" exact={true} component="ProfitLoss"></PrivateRoute>

                            <PrivateRoute path="/notepad" exact={true} component="Nodepad"></PrivateRoute>

                            <PrivateRoute path="/watchedjobs" exact={true} component="WatchdJobs"></PrivateRoute>

                            <PrivateRoute path="/samedaywidget" exact={true} component="SameDayWidget"></PrivateRoute>

                            <PrivateRoute path="/operationalHoursWidget" exact={true} component="OperationalHoursWidget"></PrivateRoute>

                            <PrivateRoute path="/asynctasks" exact={true} component="AsyncTasks"></PrivateRoute>

                            <PrivateRoute path="/clientsonstop" exact={true} component="ClientsOnStopWidget"></PrivateRoute>

                            <PrivateRoute path="/foldersummary" exact={true} component="FolderWidgetSummary"></PrivateRoute>
                            <PrivateRoute path="/folderpiechart" exact={true} component="FolderWidgetPie"></PrivateRoute>
                            <PrivateRoute path="/folderbarchart" exact={true} component="FolderWidgetBar"></PrivateRoute>
                            <PrivateRoute path="/foldertable" exact={true} component="FolderWidgetTable"></PrivateRoute>
                            <PrivateRoute path="/foldertable/:id" exact={true} component="FolderWidgetTable"></PrivateRoute>

                            <PrivateRoute
                                path="/foldermaintenance"
                                exact={true}
                                component="Folder"></PrivateRoute>

                            <PrivateRoute
                                path="/foldermaintenance/folder/:folderId"
                                exact={true}
                                component="Folder"></PrivateRoute>

                            <PrivateRoute
                                path="/rolemaintenance/new"
                                exact={true}
                                component="RoleMaintanence"></PrivateRoute>

                            <PrivateRoute
                                path="/rolemaintenance/:roleId"
                                exact={true}
                                component="RoleMaintanence"></PrivateRoute>

                            <PrivateRoute
                                path="/folder/:folderId"
                                exact={true}
                                component="RedirectTo"></PrivateRoute>

                            {authroutes &&
                                authroutes.map((route, index) => {
                                    return (
                                        !route.children &&
                                        (route.route !== 'Route' ? (
                                            <PrivateRoute
                                                key={index}
                                                path={route.path}
                                                roles={route.roles}
                                                exact={route.exact}
                                                component={route.component ? route.component : ''}></PrivateRoute>
                                        ) : (
                                            <OpenRoute
                                                key={index}
                                                path={route.path}
                                                roles={route.roles}
                                                exact={route.exact}
                                                component={route.component ? route.component : ''}></OpenRoute>
                                        ))
                                    );
                                })}

                        </Switch>
                    </Layout>
                )}


            </BrowserRouter>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.Auth.user,
        menu: state.AppMenu,
    };
};

export default connect(mapStateToProps, { initMenu, changeActiveMenuFromLocation, expandRightSidebar })(Routes);
