import {
    LOAD_FOLDER_RULES_DATA,
    LOAD_COLUMN_RULES_DATA,
    FOLDER_NAME_CHANGED,
    PARENT_FOLDER_CHANGED,
    ACCESS_GRANTED_TO_CHANGED,
    DETAILS_URI_CHANGED,
    LIST_URI_CHANGED,
    TABLE_ACCESSED_CHANGED,
    REFRESH_TABLE_AT_INTERVAL_CHANGED,
    OBJECT_TYPE_CHANGED,
    SET_FIRST_FILTER_BY_DEFAULT_CHANGED,
    SET_FIRST_ROW_BY_DEFAULT_CHANGED,
    PREVIEW_WINDOW_CHANGED,
    COLUMNS_TO_SHOW_CHANGED,
    DEFAULT_SORT_CHANGED,
    SORT_DIRECTION_CHANGED,
    HANDLE_SORT_ORDER_CHANGED,
    HANDLE_COLUMN_ALIAS_CHANGED,
    LOAD_SELECTION_RULES_DATA,
    ADD_SELECTION_RULE,
    DELETE_SELECTION_RULE,
    MOVE_UP_SELECTION_RULE,
    MOVE_DOWN_SELECTION_RULE,
    FOLDER_MAINTENANCE_OPENED,
    MOVE_UP_ACTION_RULE,
    MOVE_DOWN_ACTION_RULE,
    LOAD_ACTION_RULES_DATA,
    ADD_ACTION_RULE,
    DELETE_ACTION_RULE,
    SELECTION_TYPE_CHANGED,
    SELECTION_FIELD_CHANGED,
    SELECTION_TEST_CHANGED,
    SELECTION_VALUE_CHANGED,
    SELECTION_FOREGROUND_CHANGED,
    SELECTION_BACKGROUND_CHANGED,
    SAVE_FOLDER,
    CLEAN_UP_DATA,
    TRIGGER_FORM_SUBMITTED,
    GRID_SELECT_VALUE_CHANGED,
    GRID_INPUT_VALUE_CHANGED,
    SEQUENCE_NUMBER_CHANGED,
    RESULT_PER_PAGE_CHANGED,
    LOAD_WIDGET_RULES_DATA,
    WIDGET_FIELD_CHANGED,
    WIDGET_RULE_VALUE_CHANGED,
    ADD_WIDGET_RULE,
    SAVE_WIDGET_RULE,
    WIDGET_RULE_LABEL_CHANGED,
    DELETE_WDGET_RULE,
    WIDGET_NAME_CHANGED,
    WIDGET_SHOWCOUNT_CHANGED,
    WIDGET_TYPE_CHANGED,
    WIDGET_ACTION_ON_CHANGED,
    LOAD_FOLDER_WIDGET_LIST,
    CLEAN_WIDGET_RULES_DATA,
    CHANGE_SELECTED_WIDGET_ID,
    DELETE_FOLDER_WIDGET,
    BAR_WIDGET_GROUP_SELECT_VALUE_CHANGED,
    BAR_WIDGET_NUMBER_INPUT_CHANGED,
    BAR_GRID_SELECT_VALUE_CHANGED,
    BAR_WIDGET_RULE_LEBEL_CHANGED,
    ADD_BAR_WIDGET_RULE,
    BAR_WIDGET_RULE_DELETE,
    ADD_BAR_WIDGET_RULE_GROUP,
    BAR_WIDGET_RULE_GROUP_DELETE,
    BAR_SHOW_REST_CHECK_BOX_CHANGED,
    BAR_WIDGET_GROUP_BY_DATE_SELECT_VALUE_CHANGED,
    WIDGET_COLOR_VALUE_CHANGED,
    FOLDER_SELECT_FROM_CHANGED,
    FOLDER_SELECT_TO_NAME_CHANGED
} from './constants';

export const selectionTypeChanged = val => ({
    type: SELECTION_TYPE_CHANGED,
    payload: val
})

export const selectionFieldChanged = val => ({
    type: SELECTION_FIELD_CHANGED,
    payload: val ? val : []
})

export const selectionTestChanged = val => ({
    type: SELECTION_TEST_CHANGED,
    payload: val
})

export const selectionValueChanged = val => ({
    type: SELECTION_VALUE_CHANGED,
    payload: val
})

export const selectionForegroundChanged = val => ({
    type: SELECTION_FOREGROUND_CHANGED,
    payload: val
})

export const selectionBackgroundChanged = val => ({
    type: SELECTION_BACKGROUND_CHANGED,
    payload: val
})

export const loadFolderRulesData = val => ({
    type: LOAD_FOLDER_RULES_DATA,
    payload: val
})

export const loadColumnRulesData = val => ({
    type: LOAD_COLUMN_RULES_DATA,
    payload: val
})

export const loadSelectionRulesData = val => ({
    type: LOAD_SELECTION_RULES_DATA,
    payload: val
})

export const folderNameChanged = val => ({
    type: FOLDER_NAME_CHANGED,
    payload: val
})

export const selectFromChanged = val => ({
    type: FOLDER_SELECT_FROM_CHANGED,
    payload: val
})

export const selectToChanged = val => ({
    type: FOLDER_SELECT_TO_NAME_CHANGED,
    payload: val
})

export const parentFolderChanged = val => ({
    type: PARENT_FOLDER_CHANGED,
    payload: val
})

export const accessGrantedToChanged = val => ({
    type: ACCESS_GRANTED_TO_CHANGED,
    payload: val
})

export const refreshTableAtIntervalChanged = (val, event) => ({
    type: REFRESH_TABLE_AT_INTERVAL_CHANGED,
    payload: { value: val, event: event }
})

export const sequenceNumberChanged = val => ({
    type: SEQUENCE_NUMBER_CHANGED,
    payload: val
})

export const objectTypeChanged = val => ({
    type: OBJECT_TYPE_CHANGED,
    payload: val
})

export const detailsURIChanged = val => ({
    type: DETAILS_URI_CHANGED,
    payload: val
})

export const listURIChanged = val => ({
    type: LIST_URI_CHANGED,
    payload: val
})

export const tableAccessedChanged = val => ({
    type: TABLE_ACCESSED_CHANGED,
    payload: val
})

export const firstFilterByDefaultChanged = val => ({
    type: SET_FIRST_FILTER_BY_DEFAULT_CHANGED,
    payload: val
})

export const firstRowByDefaultChanged = val => ({
    type: SET_FIRST_ROW_BY_DEFAULT_CHANGED,
    payload: val
})

export const previewWindowChanged = val => ({
    type: PREVIEW_WINDOW_CHANGED,
    payload: val
})

export const columnsToShowChanged = val => ({
    type: COLUMNS_TO_SHOW_CHANGED,
    payload: val ? val : []
})

export const defaultSortChanged = val => ({
    type: DEFAULT_SORT_CHANGED,
    payload: val
})

export const sortDirectionChanged = val => ({
    type: SORT_DIRECTION_CHANGED,
    payload: val
})

export const handleOrderChanged = val => ({
    type: HANDLE_SORT_ORDER_CHANGED,
    payload: val
})

export const handleColumnAliasChanged = val => ({
    type: HANDLE_COLUMN_ALIAS_CHANGED,
    payload: val
})

export const addSelectionRule = () => ({
    type: ADD_SELECTION_RULE
})

export const deleteSelectionRule = val => ({
    type: DELETE_SELECTION_RULE,
    payload: val
})

export const moveUpSelectionRule = val => ({
    type: MOVE_UP_SELECTION_RULE,
    payload: val
})

export const moveDownSelectionRule = val => ({
    type: MOVE_DOWN_SELECTION_RULE,
    payload: val
})

export const folderMaitenanceOpened = val => ({
    type: FOLDER_MAINTENANCE_OPENED,
    payload: val
})

export const addActionRule = () => ({
    type: ADD_ACTION_RULE
})

export const deleteActionRule = val => ({
    type: DELETE_ACTION_RULE,
    payload: val
})

export const moveUpActionRule = val => ({
    type: MOVE_UP_ACTION_RULE,
    payload: val
})

export const moveDownActionRule = val => ({
    type: MOVE_DOWN_ACTION_RULE,
    payload: val
})

export const loadActionRulesData = val => ({
    type: LOAD_ACTION_RULES_DATA,
    payload: val
})

export const saveFolder = val => ({
    type: SAVE_FOLDER,
    payload: val
})

export const cleanUpData = () => ({
    type: CLEAN_UP_DATA
})

export const triggerFormSubmitted = () => ({
    type: TRIGGER_FORM_SUBMITTED
})

export const gridSelectValueChange = (section, key, index, val) => ({
    type: GRID_SELECT_VALUE_CHANGED,
    payload: { section: section, key: key, index: index, value: val }
})

export const gridInputValueChanged = (section, key, index, val) => ({
    type: GRID_INPUT_VALUE_CHANGED,
    payload: { section: section, key: key, index: index, value: val }
})

export const resultPerPageChanged = val => ({
    type: RESULT_PER_PAGE_CHANGED,
    payload: val
})

export const loadFolderWidgetList = val => ({
    type: LOAD_FOLDER_WIDGET_LIST,
    payload: val
})

export const loadWidgetRulesData = (folderId, widgetId) => ({
    type: LOAD_WIDGET_RULES_DATA,
    payload: { folderId: folderId, widgetId: widgetId }
})

export const widgetFieldChanged = val => ({
    type: WIDGET_FIELD_CHANGED,
    payload: val ? val : []
})

export const widgetRuleValueChanged = val => ({
    type: WIDGET_RULE_VALUE_CHANGED,
    payload: val
})

export const widgetRuleLabelChanged = val => ({
    type: WIDGET_RULE_LABEL_CHANGED,
    payload: val
})

export const addWidgetRule = () => ({
    type: ADD_WIDGET_RULE
})

export const saveWidgetRule = val => ({
    type: SAVE_WIDGET_RULE,
    payload: val
})

export const deleteWidgetRule = val => ({
    type: DELETE_WDGET_RULE,
    payload: val
})

export const widgetNameChanged = val => ({
    type: WIDGET_NAME_CHANGED,
    payload: val
})

export const widgetShowCountChanged = val => ({
    type: WIDGET_SHOWCOUNT_CHANGED,
    payload: val
})

export const widgetTypeChanged = val => ({
    type: WIDGET_TYPE_CHANGED,
    payload: val
})

export const widgetActionOnChanged = val => ({
    type: WIDGET_ACTION_ON_CHANGED,
    payload: val ? val : []
})

export const cleanWidgetRule = () => ({
    type: CLEAN_WIDGET_RULES_DATA
})

export const changeSelectedWidgetId = val => ({
    type: CHANGE_SELECTED_WIDGET_ID,
    payload: val
})

export const deleteFolderWidget = (folderId, widgetId) => ({
    type: DELETE_FOLDER_WIDGET,
    payload: { folderId: folderId, widgetId: widgetId }
})

export const barSelectGroupValueChange = (section, key, index, val) => ({
    type: BAR_WIDGET_GROUP_SELECT_VALUE_CHANGED,
    payload: { section: section, key: key, index: index, value: val }
})

export const barNoInputValueChanged = (section, key, index, val) => ({
    type: BAR_WIDGET_NUMBER_INPUT_CHANGED,
    payload: { section: section, key: key, index: index, value: val }
})

export const barGridSelectValueChange = (section, key, pIndex, chIndex, val) => ({
    type: BAR_GRID_SELECT_VALUE_CHANGED,
    payload: { section: section, key: key, pIndex: pIndex, chIndex: chIndex, value: val }
})

export const barWidgetRuleLabelChanged = (pIndex, chIndex, value) => ({
    type: BAR_WIDGET_RULE_LEBEL_CHANGED,
    payload: { pIndex: pIndex, chIndex: chIndex, value: value }
})

export const addBarWidgetRule = val => ({
    type: ADD_BAR_WIDGET_RULE,
    payload: val
})

export const barWidgetRuleDelete = (pIndex, chIndex) => ({
    type: BAR_WIDGET_RULE_DELETE,
    payload: { pIndex: pIndex, chIndex: chIndex }
})

export const addBarWidgetRuleGroup = val => ({
    type: ADD_BAR_WIDGET_RULE_GROUP,
    payload: val
})

export const barWidgetRuleGroupDelete = (pIndex, chIndex) => ({
    type: BAR_WIDGET_RULE_GROUP_DELETE,
    payload: { pIndex: pIndex }
})

export const showRestCheckBoxChange = (section, key, index, val) => ({
    type: BAR_SHOW_REST_CHECK_BOX_CHANGED,
    payload: { section: section, key: key, index: index, value: val }
})

export const barSelectGroupByDateValueChange = (section, key, index, val) => ({
    type: BAR_WIDGET_GROUP_BY_DATE_SELECT_VALUE_CHANGED,
    payload: { section: section, key: key, index: index, value: val }
})
export const colorValueChanged = val => ({
    type: WIDGET_COLOR_VALUE_CHANGED,
    payload: val
})