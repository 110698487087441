import { CHANGE_TRAFFIC_LIGHT, CHANGE_IFRAM_URL, GET_ADVANCE_SEARCH_PARAMS, CHANGE_ADD_NEW_URL, CHANGE_MY_FOLDER_ID } from './constants';

const INIT_STATE = {
    showTrafficLight: 'green',
    iframURL: '',
    selectedButon: '',
    advanceSearchParams: [],
    addNewUrl: '',
    objectType: '',
    isfavourite: false,
    ishide: false,
    folderType: '',
};

const Components = (state: State = INIT_STATE, action: LayoutAction) => {
    switch (action.type) {
        case CHANGE_TRAFFIC_LIGHT:
            return {
                ...state,
                showTrafficLight: action.payload,
            };
        case CHANGE_IFRAM_URL:
            return {
                ...state,
                iframURL: action.payload.url,
                selectedButon: action.payload.selectedButon,
            };
        case GET_ADVANCE_SEARCH_PARAMS:
            return {
                ...state,
                advanceSearchParams: action.payload,
            };
        case CHANGE_ADD_NEW_URL:
            return {
                ...state,
                addNewUrl: action.payload.url,
                objectType: action.payload.objectType,
                isfavourite: action.payload.favourite,
                ishide: action.payload.ishide,
                folderId: action.payload.folderId,
                folderType: action.payload.folderType,
            };
        case CHANGE_MY_FOLDER_ID:
            return {
                ...state,
                myFolderId: action.payload
            };
        default:
            return state;
    }
};

export default Components;
