export const API_PREFIX = '/netcourier-data/workspace/data/'

export const TOOLBAR_FORM = 'toolbar-maintenance'

// Toolbar component
export const TOOLBAR_OPENED = 'TOOLBAR_OPENED'
export const LOADING_TOOLBAR = 'LOADING_TOOLBAR'
export const LOADED_TOOLBAR = 'LOADED_TOOLBAR'
export const TOOLBAR_CLOSED = 'TOOLBAR_CLOSED'
export const SUCCESS_DISMISSED = 'SUCCESS_DISMISSED'
export const RESET_TOOLBAR_FORM = 'RESET_TOOLBAR_FORM'

// toolbar input
export const TOOLBAR_VALUE_CHANGED = 'TOOLBAR_VALUE_CHANGED'

// Input types
export const TEXT_INPUT = 'FREETXT'
export const SINGLE_SELECT = 'SINGLE_SELECT'
export const MULTI_SELECT = 'MULTI_SELECT'
export const SINGLE_SEARCH = 'SINGLE_SEARCH'
export const MULTI_SEARCH = 'MULTI_SEARCH'

export const POSITIVE_NUMBER = 'POSITIVE_NUMBER'

// export const VALIDATE_FOLDER = 'VALIDATE_FOLDER'
export const SAVE_TOOLBAR = 'SAVE_TOOLBAR'
export const SAVING_FOLDER = 'SAVING_FOLDER'
export const SAVE_FOLDER_COMPLETE = 'SAVE_FOLDER_COMPLETE'
export const SAVE_FOLDER_FAILED = 'SAVE_FOLDER_FAILED'

export const DELETE_TOOLBAR = 'DELETE_TOOLBAR'
export const DELETING_TOOLBAR = 'DELETING_TOOLBAR'
export const DELETE_TOOLBAR_COMPLETE = 'DELETE_TOOLBAR_COMPLETE'
export const DELETE_TOOLBAR_FAILED = 'DELETE_TOOLBAR_FAILED'