import {
    OPEN_MODAL,
    CLOSE_MODAL,
    START_KEY_BINDING,
    SET_KEY_BINDING,
    GET_TABLE_SHORTCUT,
    SAVE_NAVIGATION_SHORTCUT,
    SET_QUICK_ACTIONS_SHORTCUT,
    SEARCH_HOTKEY,
    SAVE_NAVIGATION_SHORTCUT_COMPLETE,
    SAVE_NAVIGATION_SHORTCUT_FAILED,
    SET_CUSTOM_TABLE_SHORTCUTS,
    GET_ALL_QUICK_ACTIONS_SHORTCUT,
    SAVE_QUICK_ACTION_SHORTCUT,
    SAVE_SHORTCUTS,
    SAVE_SHORTCUTS_COMPLETE,
    COPY_SHORTCUTS,
    SHOW_LOADER,
    UNBOUND_KEY
} from './constants';

export const openShortcutModal = () => ({
    type: OPEN_MODAL
})

export const closeShortcutModal = () => ({
    type: CLOSE_MODAL
})

export const getTableShortcuts = () => ({
    type: GET_TABLE_SHORTCUT
})


export const startKeyBind = (type: string, index: string) => ({
    type: START_KEY_BINDING,
    payload: { type: type, index: index }
})

export const setKeyBind = (type: string, index: string, key: string) => ({
    type: SET_KEY_BINDING,
    payload: { type: type, index: index, key: key },
})

export const saveShortcuts = (table: string, shortcuts: []) => ({
    type: SAVE_SHORTCUTS,
    payload: { table: table, shortcuts: shortcuts }
})

export const saveShortcutsComplete = (table: string, shortcuts: []) => ({
    type: SAVE_SHORTCUTS_COMPLETE,
    payload: { table: table, shortcuts: shortcuts }
})



export const setQuickActionShortcut = (actionShortcuts: Array) => ({
    type: SET_QUICK_ACTIONS_SHORTCUT,
    payload: { actionShortcuts: actionShortcuts }
})


export const searchHotKeys = (input: string) => ({
    type: SEARCH_HOTKEY,
    payload: input
})


export const setCustomTableShortcutState = (input: Array) => ({
    type: SET_CUSTOM_TABLE_SHORTCUTS,
    payload: input
})

export const getAllQuickActions = (actionShortcuts: Array) => ({
    type: GET_ALL_QUICK_ACTIONS_SHORTCUT,
    payload: { actionShortcuts: actionShortcuts }
})


export const copyShortcuts = (value: Array) => ({
    type: COPY_SHORTCUTS,
    payload: value
})

export const showLoader = (input: boolean) => ({
    type: SHOW_LOADER,
    payload: input
})

export const unboundKey = (type: string, index: string, existingKey: string) => ({
    type: UNBOUND_KEY,
    payload: { type: type, index: index, existingKey: existingKey }
})
