export const API_PREFIX = '/netcourier-data/shortcut/'

export const CLOSE_MODAL = 'CLOSE_MODAL'
export const OPEN_MODAL = 'OPEN_MODAL'
export const START_KEY_BINDING = 'START_KEY_BINDING'
export const SET_KEY_BINDING = 'SET_KEY_BINDING'
export const GET_TABLE_SHORTCUT = 'GET_TABLE_SHORTCUT'
export const SET_QUICK_ACTIONS_SHORTCUT = 'SET_QUICK_ACTIONS_SHORTCUT'
export const SEARCH_HOTKEY = 'SEARCH_HOTKEY'
export const SAVE_SHORTCUTS = 'SAVE_SHORTCUTS'
export const SAVE_SHORTCUTS_COMPLETE = 'SAVE_SHORTCUTS_COMPLETE'
export const SAVE_SHORTCUTS_FAILED = 'SAVE_SHORTCUTS_FAILED'
export const SET_CUSTOM_TABLE_SHORTCUTS = 'SET_CUSTOM_TABLE_SHORTCUTS'
export const GET_ALL_QUICK_ACTIONS_SHORTCUT = 'GET_ALL_QUICK_ACTIONS_SHORTCUT'
export const COPY_SHORTCUTS = 'COPY_SHORTCUTS'
export const SHOW_LOADER = 'SHOW_LOADER'
export const UNBOUND_KEY = 'UNBOUND_KEY'