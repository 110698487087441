import { useLocation } from 'react-router';
import {
    TEXT_INPUT,
    SINGLE_SELECT,
    SINGLE_SEARCH,
    MULTI_SELECT,
    MULTI_SEARCH,
    POSITIVE_NUMBER,
} from '../redux/constants';
import { ColorNames } from '../assets/CustomColors';
import FontFaceObserver from 'fontfaceobserver';

const useQuery = () => new URLSearchParams(useLocation().search);

const preventDefault = (e) => {
    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();
};

const keyValueMapper = (arr) =>
    arr.map((ob) => {
        let value = ob.value;
        ob.value = ob.key;
        ob.label = value;
        return ob;
    });

const labelMapper = (arr) => arr.map((ob) => ob.label);

const processData = (data) =>
    Object.entries(data).forEach(([key, input]) => {
        input.valid = false;
        input.modified = false;
        switch (input.type) {
            case TEXT_INPUT:
                break;
            case SINGLE_SEARCH:
            case SINGLE_SELECT:
                input.options.unshift({ value: '', label: '' });
                input.selected = input?.values.filter((o) => o?.trim().length > 0);
                input.selected = input?.options.filter((o) => o.value === input?.values[0]);
                break;
            case MULTI_SEARCH:
                input.selected = input?.options.filter((o) => input?.values.indexOf(o.value) !== -1).map(o => {
                    if (o.indexed === 'true') {
                        o.label += ' *';
                    }
                    return o;
                });
                break;
            case MULTI_SELECT:
                input.options.unshift({ value: 'SELECT_ALL', label: 'Select all' });
                input.selected = input?.options.filter((o) => input?.values.indexOf(o.value) !== -1).map(o => {
                    if (o.indexed === 'true') {
                        o.label += ' *';
                    }
                    return o;
                });
                break;
            default:
                break;
        }
    });

const setUpdatedValue = (state, node, payload) => {
    let target = { ...state };
    let input = target[node].data[payload.name];
    switch (payload.inputType) {
        case TEXT_INPUT:
            if (payload?.extra) {
                if (payload?.extra?.type === POSITIVE_NUMBER) {
                    input.value = Math.abs(payload.value);
                }
                if (input.value > payload?.extra?.max) {
                    input.value = payload?.extra?.max;
                }
            } else {
                input.value = payload.value;
            }
            input.modified = true;
            input.valid = payload.value !== null && payload.value.trim().length > 0;
            break;
        case SINGLE_SELECT:
            input.modified = true;
            input.valid = payload.value !== null && payload.value.value.trim().length > 0;
            input.selected = [payload.value];
            break;
        case MULTI_SELECT:
            input.modified = true;
            input.valid = payload.value !== null && payload.value.length > 0;
            input.selected = payload.value;
            break;
        default:
            break;
    }

    return target;
};

const resizeLeftSideBar = () => {
    let checkCondensed = document.body.getAttribute('data-leftbar-compact-mode');
    var body = document.getElementById('root'),
        html = document.documentElement;

    var height = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
    );
    let element = document.querySelector('.left-side-menu');

    if (element) {
        if (checkCondensed && checkCondensed === 'condensed') {
            element.style.height = height + 'px';
        } else {
            element.style.height = null;
        }
    }
};

const getInputPlaceHolder = (required) => (required ? 'Required' : '');

const checkAllOrNone = (options, selected) => {
    if (selected && selected.length && selected.filter(op => op.value === 'SELECT_ALL').length > 0) {
        return options.slice(1);
    }
    return selected;
};



const loadFontFaces = async () => {
    let fontLoaded = true;
    const fontNames = ['Material Design Icons', 'unicons', 'dripicons-v2'];

    const fontPromises = fontNames.map((fontName) => {
        const font = new FontFaceObserver(fontName);
        return font.load();
    });

    try {
        await Promise.all(fontPromises);
        console.log('All fonts loaded successfully.');
    } catch (error) {
        console.log('One or more fonts failed to load.');
        fontLoaded = false;
    }

    return fontLoaded;
};

const processWidgetData = (data) =>
    Object.entries(data).forEach(([key, input]) => {
        input.valid = false;
        input.modified = false;
        switch (input.type) {
            case TEXT_INPUT:
                break;
            case SINGLE_SEARCH:
            case SINGLE_SELECT:
                input.selected = input?.values.filter((o) => o?.trim().length > 0);
                input.selected = input?.options.filter((o) => o.value === input?.values[0]);
                break;
            case MULTI_SEARCH:
                input.selected = input?.options.filter((o) => input?.values.indexOf(o.value) !== -1).map(o => {
                    if (o.indexed === 'true') {
                        o.label += ' *';
                    }
                    return o;
                });
                break;
            case MULTI_SELECT:
                input.options.unshift({ value: 'SELECT_ALL', label: 'Select all' });
                input.selected = input?.options.filter((o) => input?.values.indexOf(o.value) !== -1).map(o => {
                    if (o.indexed === 'true') {
                        o.label += ' *';
                    }
                    return o;
                });
                break;
            default:
                break;
        }
    });
const getColorCode = (color) => {
    if (!color) return;
    if (color.includes("#")) return color;

    var cl = color.toLowerCase();

    let found = ColorNames[cl];
    console.log("Found:" + found + " for: " + color);
    return found;
}

const getQueryParam = (url, param) => {
    const urlParts = url.split('?');
    if (urlParts.length < 2) return '';

    const queryParams = new URLSearchParams(urlParts[1]);
    return queryParams.get(param) || '';
};

export {
    useQuery,
    preventDefault,
    keyValueMapper,
    labelMapper,
    processData,
    setUpdatedValue,
    getInputPlaceHolder,
    resizeLeftSideBar,
    checkAllOrNone,
    processWidgetData,
    getColorCode,
    loadFontFaces,
    getQueryParam
};
