// @flow

import MenuItem from './models';
import { flattenRoutes } from '../../routes';

/**
 * Flatten the nested list of menu items
 */
let uuid = 1;

const assignIdAndParent = (menuItems: Array<MenuItem>, parentId?: number, parentPath: String) => {
    menuItems = menuItems || [];
    menuItems.forEach((item) => {
        const id = item.id || uuid;
        uuid += 1;
        item.id = id;
        item.parentId = item.parentId || parentId;
        item.active = false;

        if (parentPath) {
            let myFolderPath = '';
            if (item.listurl !== undefined && item.listurl.length > 0 && item.listurl.includes('myfolderid')) {
                let splt = item.listurl.split('=');
                if (splt.length === 2) {
                    myFolderPath = '/' + splt[1];
                }
            }
            item.path = parentPath + '/' + id + '/' + item.folderId + myFolderPath;
            item.showInMenu = true;
        } else {
            if (item.folderId != '90000002') {
                item.path = '/' + id + '/' + item.folderId;
                item.showInMenu = true;
            } else {
                item.path = '/' + item.folderId;
                var foundDashboard = false;
                var toolbar = flattenRoutes(menuItems.filter((toolbar) => toolbar.name === "Toolbar"));
                toolbar.filter((item) => item.type === "T" && item.listurl === "/netcourier-data/dashboard").map((folder, i) => {               
                    foundDashboard = true;
                });
                item.showInMenu = foundDashboard;
            }
        }

        if (typeof item.children !== 'undefined') {
            assignIdAndParent(item.children, id, item.path);
        }
    });
};

export default (menuItems: Array<MenuItem>) => {
    assignIdAndParent(menuItems);
    uuid = 1;
    return menuItems;
};
